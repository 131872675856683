import React from "react";
import "./addBagModal.scss";
import { Typography, Modal, Input, Form } from "antd";

import useAddBagModal from "../../hooks/useAddBagModal";
export default function AddBagModal({
  isOpened,
  title,
  subTitle,
  onOk,
  onCancel,
}) {
  const { token, formRef, loading, error, dispatch, resetBagsError } =
    useAddBagModal(isOpened, onCancel);
  return (
    <Modal
      className="addBagModal-cont"
      open={isOpened}
      title={title}
      centered={true}
      okText="Save"
      closeIcon={false}
      onOk={() => {
        formRef.current.submit();
        dispatch(resetBagsError());
      }}
      onCancel={() => {
        onCancel();
        formRef.current.resetFields();
        dispatch(resetBagsError());
      }}
      style={{
        borderTop: `2px solid ${token?.Button?.colorPrimary}`,
      }}
      cancelButtonProps={{
        className: "addBagModal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        htmlType: "submit",
        type: "primary",
        className: "addBagModal-cont__btn",
        loading: loading,
        disabled: error ? Object.keys(error)?.length !== 0 : false,
      }}
    >
      <Form
        layout="vertical"
        ref={formRef}
        className="addBagModal-cont__form"
        onFinish={(values) => {
          onOk({
            name: values?.name,
          });
        }}
        onFinishFailed={(error) => {
        }}
      >
        <Form.Item
          name="name"
          className="addBagModal-cont__form__formItem"
          rules={[
            {
              required: true,
              message: "Bag name can't be empty",
            },
          ]}
          colon={false}
          label={
            <Typography.Text
              className="addBagModal-cont__form__formItem__text"
              style={{
                color: `${token?.Modal.titleColor}`,
              }}
            >
              {subTitle}
            </Typography.Text>
          }
        >
          <Input
            type="text"
            placeholder="Bag name"
            onChange={() => {
              if (error && error["name"]) {
                dispatch(resetBagsError("name"));
              }
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

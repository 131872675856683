import React, { useEffect } from "react";
import "./MenuModal.scss";
import { Typography, Modal, Input, Form, Checkbox } from "antd";
import useMenuModal from "../../hooks/useMenuModal";
import { useParams } from "react-router";
import { resetErrors } from "../../features/menu/menuSlice";
import { get_possible_sub_menus } from "../../features/menu/menuActions";

export default function MenuModal({
  isOpened,
  title,
  subTitle,
  //   onOk,
  onCancel,
  afterResponse,
  onChange,
}) {
  const {
    modal,
    button,
    formRef,
    error,
    loading,
    dispatch,
    subMenus,
    addSubMenu,
    isChecked,
    onChackboxChange,
    setIsChecked
  } = useMenuModal();

  const { menuId } = useParams();

  const add = (text, id, isMetrc_integrated) => {
    return dispatch(
      addSubMenu({ activeKey: menuId, name: text, id: id, subMenus, sync_with_metrc: isMetrc_integrated })
    );
  };

  const onOk = async (...args) => {
    const data = await add(...args);
    if (data.type === "addsubmenu/fulfilled") {
      onChange(data?.payload?.id);
      onCancel();
      dispatch(get_possible_sub_menus());
      formRef?.current?.setFields([
        {
          name: "name",
          value: "",
          errors: [],
        },
        {
          name: "external_id",
          value: "",
          errors: [],
        },
      ]);
      setIsChecked(false)
    }
  };

  useEffect(() => {
    if (error && !loading) {
      for (let key in error) {
        formRef?.current?.setFields([
          {
            name: key,
            errors: [error[key][0]],
          },
        ]);
      }
    }
  }, [loading, error, isOpened]);

  return (
    <Modal
      open={isOpened}
      title={title}
      onOk={() => {
        formRef.current.submit();
      }}
      okText="Save"
      closeIcon={false}
      centered
      onCancel={() => {
        onCancel();
        dispatch(resetErrors());
        formRef?.current?.setFields([
          {
            name: "name",
            value: "",
            errors: [],
          },
          {
            name: "external_id",
            value: "",
            errors: [],
          },
        ]);
        setIsChecked(false)
      }}
      style={{
        borderTop: `2px solid ${button?.colorPrimary}`,
      }}
      className="menuModal-cont"
      cancelButtonProps={{
        className: "menuModal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        htmlType: "submit",
        type: "primary",
        className: "menuModal-cont__btn",
      }}
    >
      <Form
        layout="vertical"
        ref={formRef}
        className="menuModal-cont__form"
        onFinish={(values) => {
          onOk(values["name"], values["external_id"], isChecked);
        }}
        onFinishFailed={(error) => { }}
      >
        <Form.Item
          name="name"
          className="menuModal-cont__form__formItem"
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
          colon={false}
          label={
            <Typography.Text
              className="menuModal-cont__form__formItem__text"
              style={{
                color: `${modal.titleColor}`,
              }}
            >
              {subTitle[0]}
            </Typography.Text>
          }
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          name="external_id"
          className="menuModal-cont__form__formItem"
          rules={[
            {
              required: true,
              message: "External id is required",
            },
          ]}
          colon={false}
          label={
            <Typography.Text
              className="menuModal-cont__form__formItem__text"
              style={{
                color: `${modal.titleColor}`,
              }}
            >
              {subTitle[1]}
            </Typography.Text>
          }
        >
          <Input type="text" />
        </Form.Item>
        <Checkbox onChange={onChackboxChange} checked={isChecked}>METRC Integrated</Checkbox>
      </Form>
    </Modal>
  );
}

import { theme, Select } from "antd";
import { useEffect, useRef } from "react";
import { changePassword } from "../features/changePassword/changePasswordAction";
import { useDispatch, useSelector } from "react-redux";
import { resetChangePassWordError } from "../features/changePassword/changePasswordSlice";
import { logOut } from "../features/auth/authSlice";
import { useNavigate } from "react-router";

export default function useChangePasswordModal(isOpened, onCancel) {
  const { useToken } = theme;
  const { token } = useToken();
  const navigate = useNavigate()
  const formRef = useRef(null);
  const { error, loading, success } = useSelector(
    (state) => state?.changePassWord
  );
  const dispatch = useDispatch();
  const resetPassword = (params) => {
    dispatch(changePassword(params));
  };

  useEffect(() => {
    if (isOpened) {
      if (success) {
        dispatch(resetChangePassWordError());
        onCancel();
        formRef.current.resetFields();
        dispatch(logOut())
        navigate("/");
      }
    }
  }, [success]);

  useEffect(() => {
    if (isOpened) {
      if (error && !loading) {
        for (let key in error) {
          formRef?.current?.setFields([
            {
              name: key, // Field name you want to set the error for
              errors: [error[key][0]], // Assuming your error has a 'message' field
            },
          ]);
        }
      }
    }
  }, [error, loading]);
  return {
    token,
    formRef,
    resetPassword,
    loading,
    error,
    dispatch,
    resetChangePassWordError
  };
}

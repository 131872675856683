import { theme, Select } from "antd";
import { useEffect, useRef, useState } from "react";
import Utils from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { resetError } from "../features/driver/driverSlice";
const emailValidator = Utils.emailValidator;
export default function useDriverModal(driver, isOpened, allBags, onCancel) {
  const { useToken } = theme;
  const dispatch = useDispatch();
  const { token } = useToken();
  const formRef = useRef(null);
  const { loading, error, success } = useSelector((state) => state?.driver);
  const [phoneCode, setPhoneCode] = useState("+1");
  const [formData, setFormData] = useState([]);
  const [changedFields, setChangedFields] = useState({});
  const [isFieldsChanged, setIsFieldsChanged] = useState(false);
  const phoneCodeValidator = (_, value) => {
    return new Promise((resolve, reject) => {
      if (!value && !phoneCode) {
        reject("Please enter your valid Number");
      } else if (!phoneCode && value) {
        reject("please enter Phone code");
      } else if (!/^\d+$/.test(value)) {
        reject("Phone Number must be a whole number.");
      } else {
        resolve();
      }
    });
  };
  const selectBefore = (
    <Select
      value={phoneCode ? phoneCode : "Code"}
      style={{
        width: 72,
      }}
      // value={phoneCode}
      onChange={(code) => {
        formRef.current.validateFields(["phone_number"]);
        setPhoneCode(code);
        if (error && error["phone_number"]) {
          dispatch(resetError("phone_number"));
        }
      }}
      options={[1].map((el) => ({
        value: `+${el}`,
        label: `+${el}`,
      }))}
    />
  );
  useEffect(() => {
    if (driver) {
      setFormData([
        {
          name: "first_name",
          value: driver?.first_name ? driver?.first_name : "",
        },
        {
          name: "last_name",
          value: driver?.last_name ? driver?.last_name : "",
        },
        {
          name: "phone_number",
          value: driver?.phone_number ? driver?.phone_number?.slice(2) : "",
        },
        {
          name: "email",
          value: driver?.email ? driver?.email : "",
        },
        {
          name: "bag",
          value: driver?.bag
            ? allBags?.find((el) => el?.id === driver?.bag)?.name
            : "",
        },
        {
          name: "telegram_token",
          value: driver?.telegram_token ? driver?.telegram_token : "",
        }
      ]);
      setPhoneCode(driver?.phone_number?.slice(0, 2));
    }
  }, [isOpened]);

  const handleFiledsChanged = (changedField) => {
    if (formData.length !== 0) {
      if (changedFields[changedField.name] !== changedField.value) {
        let text = changedField.name;
        let newFields = {
          ...changedFields,
          [text]: changedField.value,
        };
        for (let key in newFields) {
          const foundFild = formData?.find((el) => key === el?.name);
          if (foundFild.value === newFields[key]) {
            delete newFields[key];
          }
        }
        setChangedFields(newFields);
      }
    } else {
      if (changedFields[changedField.name] !== changedField.value) {
        let text = changedField.name;
        let newFields = {
          ...changedFields,
          [text]: changedField.value,
        };
        if (
          newFields[changedField.name] === undefined ||
          newFields[changedField.name] === ""
        ) {
          delete newFields[changedField.name];
        }
        setChangedFields(newFields);
      }
      // console.log("changedFields", changedField);
      // console.log("allFields",allFields)
    }
  };

  useEffect(() => {
    if (driver) {
      if (
        Object.keys(changedFields).length !== 0 ||
        phoneCode !== driver?.phone_number?.slice(0, 2)
      ) {
        setIsFieldsChanged(true);
      } else {
        setIsFieldsChanged(false);
      }
    }
  }, [changedFields]);

  const getSelectOptions = (haveDriverBag, availableBags) => {
    if (availableBags?.length) {
      if (haveDriverBag) {
        return [
          ...availableBags?.map((el) => ({
            label: el?.name,
            value: el?.id,
          })),
          {
            label: "Unassign bag",
            value: 0,
          },
        ];
      } else {
        return availableBags?.map((el) => ({
          label: el?.name,
          value: el?.id,
        }));
      }
    } else {
      if (haveDriverBag) {
        return [
          {
            label: "Unassign bag",
            value: 0,
          },
        ];
      }
    }
  };

  useEffect(() => {
    if (isOpened) {
      if (error && !loading) {
        for (let key in error) {
          formRef?.current?.setFields([
            {
              name: key, // Field name you want to set the error for
              errors: [error[key][0]], // Assuming your error has a 'message' field
            },
          ]);
        }
      }
    }
  }, [loading, error]);

  useEffect(() => {
    if (isOpened) {
      if (success) {
        onCancel();
        dispatch(resetError());
        formRef.current.resetFields();
        setPhoneCode("");
        setIsFieldsChanged(false);
        setChangedFields({});
      }
    }
  }, [success, dispatch]);

  return {
    token,
    formRef,
    phoneCode,
    setPhoneCode,
    phoneCodeValidator,
    selectBefore,
    formData,
    emailValidator,
    handleFiledsChanged,
    isFieldsChanged,
    setIsFieldsChanged,
    setChangedFields,
    getSelectOptions,
    getSelectOptions,
    loading,
    dispatch,
    resetError,
    error,
  };
}

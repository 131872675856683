import { Auth } from '../Auth';
const RolesHelper = {
    getRedirectPage(url) {
        if(url) {
            return url;
        }
        if (Auth.isAuthenticated()) {
            return '/admin';
        } else {
            // loginState = false;
            return '/';
        }
    }
}

export default RolesHelper;
import { createSlice } from "@reduxjs/toolkit";
import { getTopSoldBrands } from "./topSoldBrandsAction";

const initialState = {
  loading: false,
  error: null,
  success: false,
  brands:[],
};

const topSoldBrandSlice = createSlice({
  name: "topSoldBrands",
  initialState,
  reducers: {},
  extraReducers: {
    [getTopSoldBrands.pending]: (state) => {
      state.loading = true;
      state.brands = [];
      state.error = null;
    },
    [getTopSoldBrands.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.brands = payload;
      state.error = null;
    },
    [getTopSoldBrands.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
export default topSoldBrandSlice.reducer;
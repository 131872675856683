import { createSlice } from "@reduxjs/toolkit";
import { getShopSettings, patchShopSetting } from "./shopSettingsActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  shopSettings: null,
};

const shopSettingsSlice = createSlice({
  name: "shop_settings",
  initialState,
  reducers: {
    resetShopSettingError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: {
    [getShopSettings.pending]: (state) => {
      state.loading = true;
    },
    [getShopSettings.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.shopSettings = payload[0] ? payload[0] : null;
    },
    [getShopSettings.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [patchShopSetting.pending]: (state) => {
      state.loading = true;
    },
    [patchShopSetting.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.shopSettings = payload;
    },
    [patchShopSetting.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
export const { resetShopSettingError } = shopSettingsSlice.actions;
export default shopSettingsSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import axiosClient from "../../common/api/request";

export const getBrands = createAsyncThunk(
  "brands",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/brands/`,
        { params },
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getBrandProducts = createAsyncThunk(
  "brand-products",
  async (params, { rejectWithValue }) => {
    try {
      const { id, per_page } = params;
      const data = await axiosClient.get(
        `${config.API_URL}/brands/${id}/products/`,
        {params: { per_page }},
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

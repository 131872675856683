import { createSlice } from "@reduxjs/toolkit";
import { getSafes, getSafeItems, moveSafeItems } from "./safeAction";

const initialState = {
  loading: false,
  error: null,
  success: false,
  safeItems: {},
  safes: {},
};

const safeSlice = createSlice({
  name: "safe",
  initialState,
  reducers: {
    resetSafeError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSafes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSafes.fulfilled, (state, action) => {
        state.loading = false;
        state.safes = action.payload;
      })
      .addCase(getSafes.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getSafeItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSafeItems.fulfilled, (state, action) => {
        state.loading = false;
        state.safeItems = action.payload;
      })
      .addCase(getSafeItems.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(moveSafeItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(moveSafeItems.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(moveSafeItems.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.success = false
      });
  },
});
export const { resetSafeError } = safeSlice.actions;
export default safeSlice.reducer;

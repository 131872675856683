import { theme, Button, Divider } from "antd";
import {
  UnlockOutlined,
  FileDoneOutlined,
  ExportOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../features/auth/authSlice";
import useWindowWidth from "./useWindowWidth";
import Utils from "../utils";
import { removeSocketGeneratedReport } from "../features/generatedReports/generatedReportsSlice";
import { removeSocketData } from "../features/orders/ordersSlice";
import { getDrivers } from "../features/driver/driverActions";
import { getPaymentTypes } from "../features/orders/paymentTypes/paymentTypesAction";
import { getLocations } from "../features/location/locationAction";
import { getDiscounts } from "../features/discounts/discountsAction";
import { getTeamMembers } from "../features/teamMembers/teamMembersAction";

export default function useNavigation(
  isMenuClosed,
  setisMenuClosed,
  setIsChangePasswordOpened
) {
  const { pathname } = useLocation();
  const { useToken } = theme;
  const { isMobile } = useWindowWidth();
  const { token } = useToken();
  const { info: user } = useSelector((state) => state?.me);
  const drivers = useSelector((state) => state?.driver?.drivers?.results);
  const { socketData } = useSelector((state) => state?.orders);
  const { socketGeneratedReports } = useSelector(
    (state) => state?.generatedReports
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPopoverOpened, setIsPopOverOpened] = useState(false);
  const [isNotificationsOpened, setNotificationsOpened] = useState(false);
  const [isLogoutModalOpened, setIsLogOutModalOpened] = useState(false);
  const [notificationList, setNotificationLIst] = useState([]);

  const isSocketData = socketData.length ? true: false;
  useEffect(() => {
    if (isSocketData && pathname.indexOf('/admin/orders') < 0 && pathname !== '/admin/map') {
      dispatch(
        getDrivers({
          per_page: 100,
        })
      );
     
      dispatch(getPaymentTypes());
      dispatch(getLocations());
      dispatch(getDiscounts());
      dispatch(getTeamMembers({ per_page: 1000 }));
    }
  }, [isSocketData]);



  const onMenuClick = () => {
    setisMenuClosed(!isMenuClosed);
  };

  function getOrderDriver(selectedOrder) {
    const foundDriver = drivers?.find(
      (driver) => driver?.id === selectedOrder?.driver
    );

    return foundDriver
      ? `${foundDriver?.first_name} ${foundDriver?.last_name}`
      : "";
  }

  function navigateToOrders(order) {
    setNotificationsOpened(false);
    dispatch(removeSocketData({ id: order?.id }));
    const filteredList = notificationList?.filter((el) => el?.id !== order?.id);
    setNotificationLIst(filteredList);
    navigate("/admin/orders");
  }

  const statusNameMapping = (status) => {
    switch (status) {
      case "IN_PROGRESS":
        return "In Progress";
      case "DRAFT":
        return "Draft";
      case "COMPLETE":
        return "Complete";
      case "CANCELED":
        return "Canceled";
      default:
        return "";
    }
  };

  const navigateToGeneratedReports = (report) => {
    setNotificationsOpened(false);
    dispatch(removeSocketGeneratedReport({ id: report?.id }));
    const filteredList = notificationList?.filter(
      (el) => el?.id !== report?.id
    );
    setNotificationLIst(filteredList);
    navigate("/admin/generatedreports", {
      state: { report },
    });
  };

  const createNotificationList = (notificatonsData) => {
    notificatonsData = Utils.getUniqueListBy(notificatonsData, "id");
    notificatonsData = notificatonsData?.sort((a, b) => {
      const dateA = new Date(a?.socketDate);
      const dateB = new Date(b?.socketDate);

      return dateB - dateA; // For descending order
    });
    const notificationsList = notificatonsData?.map((data) => {
      const dateTime = Utils?.getFormattedDateTime(data?.socketDate)?.split(
        " "
      );
      const date = dateTime[0];
      const time = Utils?.convertToAmPm(dateTime[1]);
      if (data?.report_name) {
        return (
          <div
            onClick={() => {
              navigateToGeneratedReports(data);
            }}
            className="header-cont__popover__list"
            key={data?.id}>
            <div className="header-cont__popover__list__icon">
              <FileExcelOutlined />
            </div>
            <div className="header-cont__popover__list__container">
              <div className="header-cont__popover__list__item">
                <div>
                  <p>Report : {data?.id} </p>
                </div>
                <div>
                  <p>{statusNameMapping(data?.status)}</p>
                </div>
              </div>
              <p style={{ paddingTop: 6 }}>
                <span className="header-cont__popover__list__item__patient">
                  Report name : {data?.report_name ? data?.report_name : ""}
                </span>
                <span className="header-cont__popover__list__item__dot"></span>
                <span className="header-cont__popover__list__item__span">
                  Generated By :
                  {` ${data?.generated_by && data?.generated_by?.first_name
                      ? data?.generated_by?.first_name
                      : ""
                    } ${data?.generated_by && data?.generated_by?.last_name
                      ? data?.generated_by?.last_name
                      : ""
                    }`}
                </span>
              </p>
              <p>
                <span className="header-cont__popover__list__item__time">
                  {date + " " + time}
                </span>
              </p>
            </div>
          </div>
        );
      } else {
        return (
          <div
            onClick={() => {
              navigateToOrders(data);
            }}
            className="header-cont__popover__list"
            key={data.id}>
            <div className="header-cont__popover__list__icon">
              <FileDoneOutlined />
            </div>
            <div className="header-cont__popover__list__container">
              <div className="header-cont__popover__list__item">
                <div>
                  <p>Order #: {data?.id} </p>
                </div>
                <div>
                  <p>$ {data?.grand_total}</p>
                </div>
              </div>
              <p style={{ paddingTop: 6 }}>
                <span className="header-cont__popover__list__item__span">
                  Patient:{" "}
                  {data?.customer?.first_name + " " + data?.customer?.last_name}
                </span>
                <span className="header-cont__popover__list__item__dot"></span>
                <span className="header-cont__popover__list__item__patient">
                  Driver: {getOrderDriver(data)}
                </span>
              </p>
              <p>
                <span className="header-cont__popover__list__item__time">
                  {`${date} ${time}`}
                </span>
              </p>
            </div>
          </div>
        );
      }
    });
    return notificationsList;
  };

  useEffect(() => {
    const notificationsList = createNotificationList([
      ...socketData,
      ...socketGeneratedReports,
    ]);
    setNotificationLIst(notificationsList);
  }, [socketData, socketGeneratedReports]);

  const popoverContent = (
    <div className="header-cont__popover__content">
      <Button
        className="header-cont__popover__content__btn"
        type="text"
        icon={<UnlockOutlined />}
        onClick={() => {
          setIsPopOverOpened(false);
          setIsChangePasswordOpened(true);
        }}>
        Change Password
      </Button>
      <Divider
        type="horizontal"
        className="header-cont__popover__content__divider"
      />
      <Button
        className="header-cont__popover__content__btn"
        type="text"
        icon={<ExportOutlined />}
        onClick={() => {
          setIsPopOverOpened(false);
          setIsLogOutModalOpened(true);
        }}>
        Log Out
      </Button>
    </div>
  );

  const handleLogOut = () => {
    dispatch(logOut());
    setIsLogOutModalOpened(false);
    navigate("/");
  };

  return {
    onMenuClick,
    navigate,
    token,
    isPopoverOpened,
    setIsPopOverOpened,
    isNotificationsOpened,
    setNotificationsOpened,
    user,
    isLogoutModalOpened,
    setIsLogOutModalOpened,
    handleLogOut,
    isMobile,
    popoverContent,
    notificationList,
    socketGeneratedReports,
    socketData,
  };
}

import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import menuReducer from "../features/menu/menuSlice";
import driverReducer from "../features/driver/driverSlice";
import organizationReducer from "../features/organization/organizationSlice";
import patientReducer from "../features/patient/patientSlice";
import productReducer from "../features/product/productSlice";
import ordersReducer from "../features/orders/ordersSlice";
import settingsReducer from "../features/settings/settingsSlice";
import reportReducer from "../features/report/reportSlice";
import categoryReducer from "../features/category/categorySlice";
import meReducer from "../features/me/meSlice";
import brandsReducer from "../features/brands/brandsSlice";
import strainsReducer from "../features/strains/strainsSlice";
import rolesReducer from "../features/roles/rolesSlice";
import registerReducer from "../features/register/registerSlice";
import integrationSettingReducer from "../features/integrationSetting/integrationSettingSlice";
import driversBagsReducer from "../features/driverBags/driverBagsSlice";
import bagItemsReducer from "../features/driverBags/bagItems/bagItemsSlice";
import teamMembersReducer from "../features/teamMembers/teamMembersSlice";
import forgetPasswordReducer from "../features/forgetPassword/forgetPasswordSlice";
import topSoldProductReducer from "../features/report/topSoldProducts/topSoldProductsSlice";
import topSoldBrandReducer from "../features/report/topSoldBrands/topSoldBrandsSlice";
import topSoldCategoryReducer from "../features/report/topSoldCategories/topSoldCategoriesSlice";
import reportTaxesReducer from "../features/report/reportTaxes/reportTaxesSlice";
import changePassWordReducer from "../features/changePassword/changePasswordSlice";
import genericReportsReducer from "../features/report/genericReports/genericReportsSlice";
import dailyOrdersReducer from "../features/report/dailyOrders/dailyOrdersSlice";
import topRegionsReducer from "../features/report/topRegions/topRegionsSlice";
import paymentTypesReducer from "../features/orders/paymentTypes/paymentTypesSlice";
import groupsReducer from "../features/groups/groupsSlice";
import discountsReducer from "../features/discounts/discountsSlice";
import locationsReducer from "../features/location/locationSlice";
import generatedReportsReducer from "../features/generatedReports/generatedReportsSlice";
import metricReducer from "../features/metrics/metricSlice";
import blogsReducer from "../features/blogs/blogSlice";
import metrcSalesReducer from "../features/metrics/MetrcSales/metrcSalesSlice";
import metrcItemsReducer from "../features/metrics/MetrcItems/metrcItemsSlice";
import metrcStrainsReducer from "../features/metrics/MetrcStrains/metrcStrainsSlice";
import metrcTransactionsReducer from "../features/metrics/MetrcTransactions/metrcTransactionsSlice";
import metrcTransfersReducer from "../features/metrics/MetrcTransfers/metrcTransfersSlice";
import metrcRetaildeliveriesReducer from "../features/metrics/MetrcRetailDeliveries/metrcRetaildeliveriesSlice";
import safeReducer from "../features/safe/safeSlice";
import promoCodesReducer from "../features/promoCodes/promoCodesSlice";
import shopSettingsReducer from "../features/shopSettings/shopSettingsSlice";
import taxsettingsReducer from "../features/taxSettings/taxSettingsSlice";

export const store = configureStore({
  reducer: {
    blogs: blogsReducer,
    auth: authReducer,
    menu: menuReducer,
    safe: safeReducer,
    driver: driverReducer,
    driversBags: driversBagsReducer,
    bagItems: bagItemsReducer,
    organization: organizationReducer,
    patient: patientReducer,
    product: productReducer,
    orders: ordersReducer,
    settings: settingsReducer,
    cardReports: reportReducer,
    categorys: categoryReducer,
    me: meReducer,
    brands: brandsReducer,
    strains: strainsReducer,
    roles: rolesReducer,
    register: registerReducer,
    integrationSetting: integrationSettingReducer,
    teamMembers: teamMembersReducer,
    forgetPassWord: forgetPasswordReducer,
    topSoldProducts: topSoldProductReducer,
    topSoldBrands: topSoldBrandReducer,
    topSoldCategories: topSoldCategoryReducer,
    reportTaxes: reportTaxesReducer,
    changePassWord: changePassWordReducer,
    genericReports: genericReportsReducer,
    dailyOrders: dailyOrdersReducer,
    topRegions: topRegionsReducer,
    paymentTypes: paymentTypesReducer,
    groups: groupsReducer,
    discounts: discountsReducer,
    locations: locationsReducer,
    generatedReports: generatedReportsReducer,
    promoCodes: promoCodesReducer,
    metrics: metricReducer,
    metrcSales: metrcSalesReducer,
    metrcItems: metrcItemsReducer,
    metrcStrains: metrcStrainsReducer,
    metrcTransactions: metrcTransactionsReducer,
    metricTransfers: metrcTransfersReducer,
    metrcRetailDeliveries: metrcRetaildeliveriesReducer,
    shop_settings: shopSettingsReducer,
    taxSettings: taxsettingsReducer,
  },
});

import { createSlice } from "@reduxjs/toolkit";
import { getStrains } from "./strainsActions";

const initialState = {
  loading: false,
  error: null,
  strains: [],
};

const strainsSlice = createSlice({
  name: "strains",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStrains.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStrains.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(getStrains.fulfilled, (state, action) => {
        state.loading = false;
        state.strains = action.payload;
        state.error = null;
      })
  },
});
export default strainsSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../../config";
import axiosClient from "../../../common/api/request";

export const getMetrcStrains = createAsyncThunk(
  "get_metrc_strains",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/metrcs/strains/`,
        { params },
        config
      );
      return data;
    } catch (error) {
      if (
        error?.response &&
        error.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

export const getMetrcStrainsSync = createAsyncThunk(
  "get_metrc_strains_sync",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/metrcs/strains/sync/`,
        { params },
        config
      );
      return data;
    } catch (error) {
      if (
        error?.response &&
        error.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

export const postMetrcStrain = createAsyncThunk(
  "post_metrc_strain",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/metrcs/strains/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (
        error?.response &&
        error.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

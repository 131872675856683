import { createSlice } from "@reduxjs/toolkit";
import { deleteBagItem, getBagItems, postbagWithItem } from "./bagItemsActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  items: {},
};

const bagItemSlice = createSlice({
  name: "bagItems",
  initialState,
  reducers: {
    resetItems: (state) => {
      state.items = {};
      state.error = null;
      state.success = false;
      state.loading = false;
    },
    resetBagsItemsError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBagItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBagItems.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(getBagItems.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(deleteBagItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBagItem.fulfilled, (state, action) => {
        state.loading = false;
        state.items = {
          meta: state.items.meta,
          results: state.items.results.filter(
            (el) => el.id !== action.payload.id
          ),
        };
      })
      .addCase(deleteBagItem.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(postbagWithItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(postbagWithItem.fulfilled, (state, action) => {
        state.loading = false;
        state.items = {
          meta: state.items.meta,
          results: action.payload.items,
        };
        state.success = true;
      })
      .addCase(postbagWithItem.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.success = false;
      });
  },
});
export const { resetItems, resetBagsItemsError } = bagItemSlice.actions;
export default bagItemSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { getReportTaxes } from "./reportTaxesAction";

const initialState = {
  loading: false,
  error: null,
  success: false,
  taxes:[],
};

const reportTaxesSlice = createSlice({
  name: "reportTaxes",
  initialState,
  reducers: {},
  extraReducers: {
    [getReportTaxes.pending]: (state) => {
      state.loading = true;
      state.taxes = [];
      state.error = null;
    },
    [getReportTaxes.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.taxes = payload;
      state.error = null;
    },
    [getReportTaxes.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
export default reportTaxesSlice.reducer;
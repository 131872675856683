import { createSlice } from "@reduxjs/toolkit";
import { getMetrcItems,getMetrcItemsSync } from "./metrcItemsActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  items: {},
};

const metrcItemsSlice = createSlice({
  name: "metrcItems",
  initialState,
  reducers: {
    resetMetrcItemError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: {
    [getMetrcItems.pending]: (state) => {
      state.loading = true;
    },
    [getMetrcItems.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.items = payload;
    },
    [getMetrcItems.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getMetrcItemsSync.pending]: (state) => {
      state.loading = true;
    },
    [getMetrcItemsSync.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [getMetrcItemsSync.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { resetMetrcItemError } = metrcItemsSlice.actions;
export default metrcItemsSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { getGeneratedReports } from "./generatedReportsActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  generatedreports: {},
  socketGeneratedReports: [],
};

const generatedreportsSlice = createSlice({
  name: "generatedReports",
  initialState,
  reducers: {
    resetGeneratedReportError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
    removeSocketGeneratedReport: (state, action) => {
      if (action?.payload?.id) {
        state.socketGeneratedReports = state?.socketGeneratedReports?.filter(
          (report) => report?.id !== action?.payload?.id
        );
      } else {
        state.socketGeneratedReports = [];
      }
    },
    addSocketGeneratedReport: (state, action) => {
      state.socketGeneratedReports.push(action.payload);
    },
    addSocketGeneratedReportToGeneratedReports: (state, action) => {
      if (action?.payload && state?.generatedreports?.results?.length) {
        if (
          !state?.generatedreports?.results?.find(
            (el) => el?.id === action?.payload?.id
          )
        ) {
          state.generatedreports = {
            results: [
              action.payload,
              ...(state?.generatedreports?.results || []),
            ],
            meta: state?.generatedreports?.meta,
          };
        }
      }
    },
  },
  extraReducers: {
    [getGeneratedReports.pending]: (state) => {
      state.loading = true;
    },
    [getGeneratedReports.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.generatedreports = payload;
      state.error = null;
    },
    [getGeneratedReports.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const {
  removeSocketGeneratedReport,
  addSocketGeneratedReport,
  addSocketGeneratedReportToGeneratedReports,
  resetGeneratedReportError,
} = generatedreportsSlice.actions;
export default generatedreportsSlice.reducer;

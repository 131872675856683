import { createSlice } from "@reduxjs/toolkit";
import { changePassword } from "./changePasswordAction";
import LocalStorageService from "../../common/services/LocalStorageService";

const initialState = {
  loading: false,
  error: null,
  message: null,
  success: false,
};

const changePassWordSlice = createSlice({
  name: "changePassWord",
  initialState,
  reducers: {
    resetChangePassWordError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.success = true;
        state.message = action?.payload?.message;
        state.loading = false;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.success = false;
        state.message = null 
      });
  },
});
export const { resetChangePassWordError } = changePassWordSlice.actions;
export default changePassWordSlice.reducer;

import { theme } from "antd";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetBagsError } from "../features/driverBags/driverBagsSlice";
export default function useAddBagModal(isOpened, onCancel) {
  const formRef = useRef(null);
  const { useToken } = theme;
  const { token } = useToken();
  const { loading, error, success } = useSelector(
    (state) => state?.driversBags
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (isOpened) {
      if (error && !loading) {
        for (let key in error) {
          formRef?.current?.setFields([
            {
              name: key, // Field name you want to set the error for
              errors: [error[key][0]], // Assuming your error has a 'message' field
            },
          ]);
        }
      }
    }
  }, [loading, error]);

  useEffect(() => {
    if (isOpened) {
      if (success) {
        onCancel();
        formRef.current.resetFields();
        dispatch(resetBagsError());
      }
    }
  }, [success, dispatch]);

  return {
    token,
    formRef,
    loading,
    error,
    dispatch,
    resetBagsError,
  };
}

import { theme } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetSafeError } from "../features/safe/safeSlice";
import { get_possible_sub_menus } from "../features/menu/menuActions";

export default function useMoveMenuItemModal(
  currentMenuId,
  currentSubMenuId,
  isOpened,
  onCancel,
  setSelectedRowKeys,
  formRef
) {
  const { useToken } = theme;
  const { token } = useToken();
  const {
    loading,
    menu: { results: menuOptions },
    subMenu: { results: subMenus },

    possible_sub_menus,
  } = useSelector((state) => state?.menu);
  const { success, error,loading:safeLoading } = useSelector((state) => state?.safe);
  const selectOptionsMenu = menuOptions?.filter(
    (el) => el?.id?.toString() !== currentMenuId
  );
  const dispatch = useDispatch();
  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    dispatch(get_possible_sub_menus());
  }, []);

  useEffect(() => {
    const filtered_subMenus = possible_sub_menus?.filter(
      (el) => el?.id?.toString() !== currentSubMenuId
    );
    const dataforOptions = filtered_subMenus?.map((subMenu) => {
      return {
        label: `(${
          menuOptions?.find((menu) => menu?.id === subMenu?.menu)?.name
        }) ${subMenu?.name}`,
        value: subMenu?.id,
      };
    });
    setSelectOptions(dataforOptions);
  }, [possible_sub_menus, currentSubMenuId, currentMenuId, menuOptions]);

  const filterOption = (input, option) =>
    (option?.label?.replace(/\((.*?)\)/g, "$1") ?? "")
      ?.toLowerCase()
      ?.includes(input?.toLowerCase());

  useEffect(() => {
    if (isOpened) {
      if (error && !loading) {
        for (let key in error) {
          formRef?.current?.setFields([
            {
              name: key, // Field name you want to set the error for
              errors: [error[key][0]], // Assuming your error has a 'message' field
            },
          ]);
        }
        // const subMenusFieldError = formRef?.current?.getFieldError("sub_menu");
        // if (!subMenusFieldError?.length) {
        //   const errorText = error?.error ? error?.error : "Invalid sub menu";
        //   formRef?.current?.setFields([
        //     {
        //       name: "sub_menu", // Field name you want to set the error for
        //       errors: [errorText], // Assuming your error has a 'message' field
        //     },
        //   ]);
        // }
      }
    }
  }, [safeLoading, error]);

  useEffect(() => {
    if (isOpened) {
      if (success) {
        onCancel();
        formRef.current.resetFields();
        dispatch(resetSafeError());
        setSelectedRowKeys([]);
      }
    }
  }, [success, dispatch]);

  return {
    formRef,
    token,
    selectOptionsMenu,
    loading,
    error,
    dispatch,
    selectOptions,
    filterOption,
    resetSafeError,
    safeLoading
  };
}

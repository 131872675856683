import { theme } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetGroupError } from "../features/groups/groupsSlice";

export default function useRoleModal(group, onCancel, isOpened) {
  const { useToken } = theme;
  const dispatch = useDispatch();
  const { token } = useToken();

  const [selecTedPermisssions, setSelectedPermissions] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const { permissions, error, success, loading } = useSelector(
    (state) => state?.groups
  );
  const allPermissions = (() => {
    let res = [];
    for (let key in permissions) {
      res = [...res, ...permissions[key]?.map((el) => el?.id)];
    }
    return res;
  })();

  const checkAll = allPermissions?.length === selecTedPermisssions?.length;
  const indeterminate =
    selecTedPermisssions?.length > 0 &&
    selecTedPermisssions?.length < allPermissions?.length;

  const allKeysexpanded = [...Object.keys(permissions)];
  const expandAll = allKeysexpanded?.length === expandedKeys?.length;
  const indeterminateForExpansion =
    expandedKeys?.length > 0 && expandedKeys?.length < allKeysexpanded?.length;
  const formRef = useRef(null);
  const [formData, setFormData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const objectFromAray = (obj) => {
    let res = {};
    const parents = Object?.keys(obj);
    for (let i = 0; i < parents?.length; i++) {
      res[parents[i]] = [];
    }
    return res;
  };

  const [selectedPermissionsByParent, setSelectedPermissionsByParent] =
    useState({});

  const treeConverter = (permissions) => {
    return Object.entries(permissions)?.map(([key, value]) => ({
      title: key,
      key: key,
      children: value.map((permission) => ({
        title: permission?.name,
        key: permission?.id,
      })),
    }));
  };

  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    setExpandedKeys([...Object.keys(permissions)]);
    setTreeData(treeConverter(permissions));
    setSelectedPermissionsByParent(objectFromAray(permissions));
  }, [permissions]);

  useEffect(() => {
    if (group) {
      setSelectedPermissions(group?.permissions);
      setFormData([
        {
          name: "label",
          value: group?.label ? group?.label : "",
        },
      ]);
      const groupPermissionByParent = {};
      Object.entries(permissions)?.forEach(([parentKey, children]) => {
        children?.forEach((child) => {
          if (group?.permissions?.includes(child?.id)) {
            if (!groupPermissionByParent[parentKey]) {
              groupPermissionByParent[parentKey] = [];
            }
            groupPermissionByParent[parentKey]?.push(child?.id);
          }
        });
      });

      setSelectedPermissionsByParent(groupPermissionByParent);
    }
  }, [group]);

  useEffect(() => {
    if (isOpened) {
      if (error && !loading) {
        for (let key in error) {
          formRef?.current?.setFields([
            {
              name: key, // Field name you want to set the error for
              errors: [error[key][0]], // Assuming your error has a 'message' field
            },
          ]);
        }
      }
    }
  }, [error, loading]);

  useEffect(() => {
    if (isOpened) {
      if (success) {
        onCancel();
        dispatch(resetGroupError());
        formRef.current.resetFields();
        setSelectedPermissions([]);
        setSearchValue("");
        setExpandedKeys([...Object.keys(permissions)]);
        setSelectedPermissionsByParent(
          makeEmptySelectedPermissionsByParent(selectedPermissionsByParent)
        );
      }
    }
  }, [success]);

  const handleFildChanged = (rolePerm, selectedperm, label) => {
    if (group?.label !== label) {
      return true;
    }
    if (selectedperm?.length >= rolePerm?.length) {
      for (let i = 0; i < selectedperm?.length; i++) {
        if (!rolePerm?.includes(selectedperm[i])) {
          return true;
        }
      }
    } else {
      for (let i = 0; i < rolePerm?.length; i++) {
        if (!selectedperm?.includes(rolePerm[i])) {
          return true;
        }
      }
    }

    return false;
  };

  const makeEmptySelectedPermissionsByParent = (obj) => {
    const res = {};
    for (let key in obj) {
      res[key] = [];
    }
    return res;
  };

  const onCheckAllChange = (e) => {
    let allPermissions = [];
    if (e?.target.checked) {
      const updatedPermissionByParent = {};
      for (let key in permissions) {
        updatedPermissionByParent[key] = permissions[key]?.map((el) => el?.id);
        allPermissions = [
          ...allPermissions,
          ...permissions[key]?.map((el) => el?.id),
        ];
      }
      setSelectedPermissionsByParent(updatedPermissionByParent);
      setSelectedPermissions(allPermissions);
    } else {
      const updatedPermissionByParent = makeEmptySelectedPermissionsByParent(
        selectedPermissionsByParent
      );
      setSelectedPermissionsByParent(updatedPermissionByParent);
      setSelectedPermissions([]);
    }
  };

  const onCheckAllExpandChange = (e) => {
    if (e.target.checked) {
      setExpandedKeys([...Object.keys(permissions)]);
    } else {
      setExpandedKeys([]);
    }
  };

  const onCheck = (selectedKeys, parent) => {
    const parentNodes = Object.keys(permissions);
    const newPermissionsWithoutParent = selectedKeys?.filter(
      (perm) => !parentNodes?.includes(perm)
    );
    const updatedPermissionsbyParent = {
      ...selectedPermissionsByParent,
      [parent]: newPermissionsWithoutParent,
    };

    setSelectedPermissionsByParent(updatedPermissionsbyParent);
    let updatedPermissions = [];
    for (let key in updatedPermissionsbyParent) {
      updatedPermissions = [
        ...updatedPermissions,
        ...updatedPermissionsbyParent[key],
      ];
    }
    setSelectedPermissions(updatedPermissions);
  };

  const onExpand = (key) => {
    if (expandedKeys?.includes(key)) {
      const newexpandeKeys = expandedKeys?.filter((el) => el !== key);
      setExpandedKeys(newexpandeKeys);
    } else {
      setExpandedKeys([...expandedKeys, key]);
    }
  };

  function searchGroups() {
    const lowercasedTerm = searchValue?.toLowerCase();
    return treeData.reduce((acc, group) => {
      const titleMatch = group?.title?.toLowerCase()?.includes(lowercasedTerm);
      const childrenMatches = group?.children?.filter((child) =>
        child?.title?.toLowerCase()?.includes(lowercasedTerm)
      );
      const highlightTitle = (str, term) => {
        const index = str?.toLowerCase()?.indexOf(term);
        if (index === -1) return <span>{str}</span>;
        const beforeStr = str?.substring(0, index);
        const afterStr = str?.slice(index + term?.length);
        return (
          <span>
            {beforeStr}
            <span
              style={{
                color: token?.colorPrimary,
              }}
            >
              {str?.substring(index, index + term.length)}
            </span>
            {afterStr}
          </span>
        );
      };
      let highlitedChildren;
      if (childrenMatches?.length) {
        highlitedChildren = group?.children?.map((child) => ({
          title: highlightTitle(child?.title, lowercasedTerm),
          key: child?.key,
        }));
      }

      if (titleMatch) {
        // If the title of the group matches, add the whole group
        //    acc.push(group);
        acc.push({
          ...group,
          title: highlightTitle(group?.title, lowercasedTerm),
          children:
            childrenMatches?.length > 0 ? highlitedChildren : group?.children,
        });
      } else if (childrenMatches?.length > 0) {
        // If only specific children match, create a new group with these matching children
        acc.push({
          ...group,
          children: highlitedChildren,
        });
      }

      return acc;
    }, []);
  }

  const filteredeTreeData = searchGroups();

  const onSearchBarchange = (text) => {
    setSearchValue(text);
  };
  
  return {
    token,
    selecTedPermisssions,
    setSelectedPermissions,
    formRef,
    formData,
    loading,
    error,
    success,
    dispatch,
    permissions,
    resetGroupError,
    handleFildChanged,
    onCheckAllChange,
    checkAll,
    indeterminate,
    searchValue,
    setSearchValue,
    onCheck,
    onExpand,
    expandedKeys,
    setExpandedKeys,
    selectedPermissionsByParent,
    makeEmptySelectedPermissionsByParent,
    setSelectedPermissionsByParent,
    onSearchBarchange,
    filteredeTreeData,
    indeterminateForExpansion,
    expandAll,
    onCheckAllExpandChange,
  };
}

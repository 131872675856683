import { createSlice } from "@reduxjs/toolkit";
import { getDailyOrders } from "./dailyOrdersAction";

const initialState = {
  loading: false,
  error: null,
  success: false,
  dailyProducts: [],
};

const dailyOrdersSlice = createSlice({
  name: "dailyOrders",
  initialState,
  reducers: {},
  extraReducers: {
    [getDailyOrders.pending]: (state) => {
      state.loading = true;
      state.dailyProducts = [];
      state.error = null;
    },
    [getDailyOrders.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.dailyProducts = payload;
      state.error = null;
    },
    [getDailyOrders.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
export default dailyOrdersSlice.reducer;

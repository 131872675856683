import React from "react";
import "./MetrcStrainModal.scss";
import { Modal, Form, Typography, Input, Button, Slider, Select } from "antd";
import useMetrcStrainModal from "../../hooks/useMetrcStrainModal";
import Utils from "../../utils";

export default function MetrcStrainModal({ isOpened, title, onOk, onCancel }) {
  const {
    token,
    loading,
    error,
    formRef,
    dispatch,
    resetMetrcStrainError,
    formData,
    value,
    setValue,
    onSLiderChange,
    isTouchedOnSLider,
    setIsTouchedOnSlider,
  } = useMetrcStrainModal(isOpened, onCancel);
  return (
    <Modal
      className="metrcStrainModal-cont"
      style={{
        borderTop: `2px solid ${token?.Button?.colorPrimary}`,
      }}
      open={isOpened}
      title={title}
      centered={true}
      okText="Save"
      closeIcon={false}
      cancelButtonProps={{
        className: "metrcStrainModal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        className: "metrcStrainModal-cont__btn",
        htmlType: "submit",
        type: "primary",
        key: "submit",
        loading: loading && isOpened,
        disabled: error ? Object.keys(error)?.length !== 0 : false,
      }}
      onCancel={() => {
        onCancel();
        formRef?.current?.resetFields();
        setIsTouchedOnSlider(false);
        setValue(0);
        if (error) {
          dispatch(resetMetrcStrainError());
        }
      }}
      onOk={() => {
        formRef?.current?.submit();
      }}
      footer={(_, { OkBtn, CancelBtn }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}>
          <CancelBtn />
          <Button size="small">Add new strain</Button>
          <OkBtn />
        </div>
      )}>
      <Form
        className="metrcStrainModal-cont__form"
        ref={formRef}
        layout="vertical"
        onFinish={(values) => {
          onOk({
            cbd_level: values?.cbd_level ? Number(values?.cbd_level) : "",
            name: values?.name,
            testing_status: values?.testing_status,
            thc_level: values?.thc_level ? Number(values?.thc_level) : "",
            indica_percentage: value,
            sativa_percentage: 100 - value,
          });
        }}
        onFinishFailed={(error) => {
          console.log("onFinishFailed", error);
        }}
        fields={formData}>
        <div className="metrcStrainModal-cont__form__formItemWrapper">
          <Form.Item
            className="metrcStrainModal-cont__form__formItemWrapper__formItem"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter name",
              },
            ]}
            label={
              <Typography.Text className="metrcStrainModal-cont__form__formItemWrapper__formItem__text">
                METRC Strain Name
              </Typography.Text>
            }
            colon={false}>
            <Input
              placeholder="Strain Name"
              onChange={() => {
                if (error && error["name"]) {
                  dispatch(resetMetrcStrainError("name"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="metrcStrainModal-cont__form__formItemWrapper">
          <Form.Item
            className="metrcStrainModal-cont__form__formItemWrapper__formItem"
            name="thc_level"
            rules={[
              {
                required: true,
                message: "Please enter THC level",
              },
              { validator: Utils?.numberValidator },
            ]}
            label={
              <Typography.Text className="metrcStrainModal-cont__form__formItemWrapper__formItem__text">
                THC Level
              </Typography.Text>
            }
            colon={false}>
            <Input
              placeholder="THC Level"
              onChange={() => {
                if (error && error["thc_level"]) {
                  dispatch(resetMetrcStrainError("thc_level"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="metrcStrainModal-cont__form__formItemWrapper">
          <Form.Item
            className="metrcStrainModal-cont__form__formItemWrapper__formItem"
            name="cbd_level"
            rules={[
              {
                required: true,
                message: "Please enter CBD level",
              },
              { validator: Utils?.numberValidator },
            ]}
            label={
              <Typography.Text className="metrcStrainModal-cont__form__formItemWrapper__formItem__text">
                CBD Level
              </Typography.Text>
            }
            colon={false}>
            <Input
              placeholder="CBD Level"
              onChange={() => {
                if (error && error["cbd_level"]) {
                  dispatch(resetMetrcStrainError("cbd_level"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="metrcStrainModal-cont__form__formItemWrapperSlider">
          <div className="metrcStrainModal-cont__form__formItemWrapperSlider__formItem__sliderTextWrapper">
            <Typography.Text
              className="metrcStrainModal-cont__form__formItemWrapperSlider__formItem__sliderTextWrapper__text"
              style={{
                color: token?.Typography.baseColorText,
              }}>
              {isTouchedOnSLider ? `Indica ${value} %` : "Indica"}
            </Typography.Text>
            <Typography.Text
              className="metrcStrainModal-cont__form__formItemWrapperSlider__formItem__sliderTextWrapper__text"
              style={{
                color: token?.Typography?.secondaryColorText,
              }}>
              {isTouchedOnSLider ? ` Sativa ${100 - value} %` : "Sativa"}
            </Typography.Text>
          </div>
          <Form.Item
            className="metrcStrainModal-cont__form__formItemWrapperSlider__formItem"
            name="slider"
            rules={[
              {
                required: true,
                message: "Please enter sativa and indica",
              },
            ]}
            colon={false}>
            <Slider
              onChange={onSLiderChange}
              railStyle={{
                background: isTouchedOnSLider ? token?.colorSecondaryLogo : "",
              }}
            />
          </Form.Item>
        </div>
        <div className="metrcStrainModal-cont__form__formItemWrapper">
          <Form.Item
            className="metrcStrainModal-cont__form__formItemWrapper__formItem"
            name="testing_status"
            rules={[
              {
                required: true,
                message: "Please select testing status",
              },
            ]}
            label={
              <Typography.Text className="metrcStrainModal-cont__form__formItemWrapper__formItem__text">
                Testing Status
              </Typography.Text>
            }
            colon={false}>
            <Select
              placeholder="Testing Status"
              options={[
                { label: "None", value: "None" },
                { label: "ThirdParty", value: "ThirdParty" },
                { label: "InHouse", value: "InHouse" },
              ]?.map((el) => ({
                label: el?.label,
                value: el?.value,
              }))}
              onChange={() => {
                if (error && error["testing_status"]) {
                  dispatch(resetMetrcStrainError("testing_status"));
                }
              }}
              allowClear
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { getCategorys, getCategoriesPerPage } from "./categoryActions";

const initialState = {
  loading: false,
  perPageLoading: false,
  error: null,
  categorys: [],
  categoriesPerPage: {}
};

const categorySlice = createSlice({
  name: "driver",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategorys.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCategorys.fulfilled, (state, action) => {
        state.loading = false;
        state.categorys = action.payload;
        state.error = null;
      })
      .addCase(getCategorys.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(getCategoriesPerPage.pending, (state, action) => {
        state.perPageLoading = true;
      })
      .addCase(getCategoriesPerPage.fulfilled, (state, action) => {
        state.perPageLoading = false;
        state.categoriesPerPage = action.payload;
      })
      .addCase(getCategoriesPerPage.rejected, (state, action) => {
        state.perPageLoading = false;
      })
  },
});
export default categorySlice.reducer;

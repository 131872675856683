import { createSlice } from '@reduxjs/toolkit'
import { getSettings,updateSettings } from './settingsActions'


const initialState = {
  loading: false,
  error: null,
  success: false,
  settings:{}
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: {
    [getSettings.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [getSettings.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.settings = payload
      state.error = null
    },
    [getSettings.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [updateSettings.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [updateSettings.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.settings = payload
      state.error = null
    },
    [updateSettings.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
})
export const { resetError } = settingsSlice.actions;
export default settingsSlice.reducer
import axios from "axios";
import config from "../../config";
import LocalStorageService from "../services/LocalStorageService";

const axiosClient = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

axiosClient.interceptors.request.use(
  (request) => {
    const token = LocalStorageService.get("authorizationData")?.access;
    if (request.url === `${config.API_URL}/images/`) {
      request.headers["Content-Type"] = 'multipart/form-data'
    }
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    if (response === undefined) {
      return Promise.reject(
        new Error("Incorrect response format, the server might be unavailable")
      );
    }
    return response.data;
  },
  (error) => {
    if (error.response?.status === 401) {
      // const { config } = error
      // const authData = LocalStorageService.get("authorizationData");
      // if (authData && authData.refresh) {
      // Do not refresh token for interval calls and stop interval until token will be refreshed
      // return refreshToken(authData, config)
      // } else {
      LocalStorageService.remove("authorizationData");
      if (window.location.pathname !== '/') {
        window.location.href = "/";
      }

      // }

      // Will handle unauthorized error later
    }
    return Promise.reject(error);
  }
);

export default axiosClient;

export const theme = Object.freeze({
  token: {
    fontFamily: `-apple-system, BlinkMacSystemFont, 'SF Pro Text', 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji'`,
    // please read this comment !!!!!!!!! if i am not mystaken this can help us for icons that we dont have control forexzample input=search
    colorPrimary: "#61BC18",
    colorSecondaryLogo: "rgba(173,62,240,255)",
  },
  components: {
    Layout: {
      algorithm: true, // Enable algorithm
      colorBgLayout: "#001529",
      contentBg: "#FFF",
    },
    Header: {
      saitHeaderBorder: "#FFF",
      boxShadow: " 3px 0px 0px 0px #FFF inset",
    },
    Button: {
      algorithm: true, // Enable algorithm
      colorPrimary: "#61BC18",
      defaultBg: "#FFF",
      fontWeight: 400,
      defaultBorderColor: "#d9d9d9",
      // colorText: "#FFF",
      controlHeight: 40,
      fontSize: 16,
      colorLink: "#61BC18",
    },
    Menu: {
      algorithm: true,
      itemSelectedColor: "#FFFFFF",
      itemSelectedBg: "#61BC18",
      colorBgBase: "#001529",
      itemColor: "#FFFFFFA6",
      itemHoverColor: "#FFFFFF",
      itemHoverBg: "#61BC18",
      itemPaddingInline: "24px",
      subMenuItemBg: "#000C17",
    },
    Tabs: {
      cardBg: "#001529",
      itemColor: "#FFF",
      colorBorderSecondary: "transparent",
      cardGutter: 0,
      colorBorder: "transparent",
      horizontalMargin: "0",
      fontSizeLG: 16,
    },
    Segmented: {
      colorText: "#000",
      colorBgElevated: "#FFF", //this is for only one item backGroundCOlor
      colorBgLayout: "rgba(0, 0, 0, 0.04)",
      controlPaddingHorizontal: 13,
      controlHeight: 40,
    },
    // Select: {
    //   // controlHeight: 40,
    // },
    Input: {
      hoverBorderColor: "#61BC18",
      colorBorder: "#d9d9d9",
      activeBorderColor: "#61BC18",
    },
    Table: {
      cellPaddingInline: 8,
      cellPaddingBlock: 8,
      colorText: "#000",
      iconWrapperBorderColor: "#d9d9d9",
      headerBg: "#fafafa",
    },
    Modal: {
      boxShadow:
        "0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08)",
      titleFontSize: 16,
      titleColor: "#000",
      colorPrimaryBorder: "rgba(0, 0, 0, 0.15)",
      trasparentBg: "transparent",
    },
    Typography: {
      colorText: "#000000E0",
      whieteColorText: "#FFF",
      baseColorText: "#61BC18",
      grayColorText: "rgba(255, 255, 255, 0.60)",
      secondaryColorText: "rgba(173,62,240,255)",
    },
    icons: {
      // it seems me that this is comfortable option for cntroling icons color from one place
      whiteIcon: "#FFF",
      baseColor: "#61BC18",
      userIcon: "#00000073",
      erorrColorIcon: "#ff4d4f",
      grayIcon: "#ffffff99",
    },
    Card: {
      colorPrimary: "#61BC18",
      boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.08)",
      paddingLG: 12,
      headerHeight: 46,
    },
    Form: {
      itemMarginBottom: 0,
    },
    Dropdown: {
      algorithm: true,
      baseColorText: "#1677FF",
    },
    Collapse: {
      borderTopColor: "#61BC18",
      boxShadow: " 0px 0px 8px 0px rgba(0, 0, 0, 0.12)",
    },
    // Radio: {
    //     dotSize: 24,
    //     radioSize: 24,
    //     buttonBg: '#1677FF',
    //     buttonCheckedBg: '#ff4d4f',
    //     buttonColor: 'red',
    // }
  },
});

export const invertTheme = Object.freeze({
  token: {
    colorPrimary: "rgba(255, 255, 255, 0.60)",
    colorBgContainer: "#001529",
    colorText: "#FF0000",
    colorTextPlaceholder: "rgba(255, 255, 255, 0.60)",
    colorText: "rgba(255, 255, 255, 0.60)",
    colorBgElevated: "#001529",

    colorPrimary: "#4d3232",
    colorInfo: "#4d3232",
    colorLink: "#4230b1",
    colorBgBase: "#404f93",
    wireframe: false,
    colorTextBase: "#f50505",
  },
  components: {
    Select: {},
    TimePicker: {},
  },
});

// if you see baseColor it means taht the color of that property will be th same as menu's selected state color and etc.

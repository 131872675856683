import { theme } from "antd";

export default function useConfirmMenuModal() {
    const { useToken } = theme;
    const {
        token: { Modal: modal, Button: button,Typography:text },
      } = useToken();
      const {token} = useToken()

      return {
        modal,
        button,
        text,
        token
      }
}

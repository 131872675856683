import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import axiosClient from "../../common/api/request";

export const getItems = createAsyncThunk(
  "menu_items",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/menu_items/`,
        { params },
        config
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getItem = createAsyncThunk(
  "getmenuitem",
  async (id, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/menu_items/${id}/`,
        // { params },
        config
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteItem = createAsyncThunk(
  "deletemenuitem",
  async (id, { rejectWithValue }) => {
    try {
      const data = await axiosClient.delete(
        `${config.API_URL}/menu_items/${id}/`,
        {}, // { params },
        config
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getMenu = createAsyncThunk(
  "menus",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/menus/`,
        {},
        config
      );

      return data;
    } catch (error) {
      // return custom error message from API if any-
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const resetSubMenus = createAsyncThunk(
  "resetSubMenus",
  async (params, { rejectWithValue }) => {
    return;
  }
);

export const getSubMenus = createAsyncThunk(
  "submenus",
  async (id, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/menus/${id}/sub_menus/`,
        {},
        config
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addSubMenu = createAsyncThunk(
  "addsubmenu",
  async ({ activeKey, name, id, sync_with_metrc }, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/menus/${activeKey}/sub_menus/`,
        { name, external_id: id, sync_with_metrc, },
        config
      );
      return data;
    } catch (error) {
      if (error.response && Object.keys(error.response.data).length > 0) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editSubMenu = createAsyncThunk(
  "editsubmenu",
  async ({ menuId, id, text, external_id, sync_with_metrc }, { rejectWithValue }) => {
    try {
      const data = await axiosClient.patch(
        `${config.API_URL}/menus/${menuId}/sub_menus/${id}`,
        { name: text, sync_with_metrc, external_id },
        config
      );
      return data;
    } catch (error) {
      if (error.response && Object.keys(error.response.data).length > 0) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const removeSubMenu = createAsyncThunk(
  "removesubmenu",
  async ({ menuId, id }, { rejectWithValue }) => {
    try {
      const data = await axiosClient.delete(
        `${config.API_URL}/menus/${menuId}/sub_menus/${id}`,
        {},
        config
      );
      return id;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addMenu = createAsyncThunk(
  "addmenu",
  async ({ name }, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/menus`,
        { name },
        config
      );

      return data;
    } catch (error) {
      if (error.response && Object.keys(error.response.data).length > 0) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const removeMenu = createAsyncThunk(
  "removemenu",
  async (id, { rejectWithValue }) => {
    try {
      const data = await axiosClient.delete(
        `${config.API_URL}/menus/${id}`,
        {},
        config
      );
      return id;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateMenu = createAsyncThunk(
  "updateMenu",
  async ({ id, updated_name }, { rejectWithValue }) => {
    try {
      const data = await axiosClient.patch(
        `${config.API_URL}/menus/${id}`,
        { name: updated_name },
        config
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && Object.keys(error.response.data).length > 0) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postImage = createAsyncThunk(
  "postImage",
  async (formData, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/images/`,
        formData,
        config
      );

      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getVariants = createAsyncThunk(
  "variants",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/variants/`,
        {},
        config
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createMenuItem = createAsyncThunk(
  "create-custom-menuitem",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/menu_items/`,
        params,
        config
      );

      return data;
    } catch (error) {
      // return custom error message from API if any
      //   if (error.response && error.response.data.message) {
      //     return rejectWithValue(error.response.data.message);
      //   } else {
      //     return rejectWithValue(error.message);
      //   }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateMenuItem = createAsyncThunk(
  "update-menuitem",
  async (params, { rejectWithValue }) => {
    const { id, ...serverData } = params;
    try {
      const data = await axiosClient.patch(
        `${config.API_URL}/menu_items/${id}`,
        serverData,
        config
      );

      return data;
    } catch (error) {
      // debugger;
      // return custom error message from API if any
      //   if (error.response && error.response.data.message) {
      //     return rejectWithValue(error.response.data.message);
      //   } else {
      //     return rejectWithValue(error.message);
      //   }
      return rejectWithValue(error.response.data);
    }
  }
);

export const get_possible_sub_menus = createAsyncThunk(
  "get_possible_sub_menus",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/sub_menus/`,
        { params },
        config
      );

      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sync_menu_items = createAsyncThunk(
  "sync_menu_item",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/menu_items/sync/`,
        params,
        config
      );

      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

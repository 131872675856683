
import { Auth } from "../Auth";
import LocalStorageService from "../LocalStorageService";
import RolesHelper from "./RolesHelper";
import { Navigate } from "react-router-dom";

const Can = (props) => {
    const { I, children, fallback, a, withRedirect, login } = props;
    const storedData = LocalStorageService.get('authorizationData');
    const user = storedData || {};
    const { name, group_permissions = [] } = user;
    const superAdmin = storedData?.is_superuser;
    let returnCondition = group_permissions.includes(I + '_' + a) || superAdmin;
    // if (superAdmin) {
        // return children;
        // }

    // Auth check if user can see a page
    if (I + '_' + a === 'view_page') {
        returnCondition = Auth.isAuthenticated() === login;
    }
    const fb = withRedirect ? <Navigate to={RolesHelper.getRedirectPage()} /> : fallback;
    return returnCondition ? children : typeof fb !== 'undefined' ? fb : <></>;
}
export default Can;
import { createSlice } from "@reduxjs/toolkit";
import { getPaymentTypes } from "./paymentTypesAction";

const initialState = {
  loading: false,
  error: null,
  success: false,
  options: [],
};

const paymentSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPaymentTypes.fulfilled, (state, action) => {
        state.options = action.payload;
        state.loading = false;
      })
      .addCase(getPaymentTypes.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default paymentSlice.reducer;

import { createSlice } from '@reduxjs/toolkit'
import { getProducts } from './productActions'

const initialState = {
  loading: false,
  error: null,
  success: false,
}

const productSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {},
  extraReducers: {
    // login user
    [getProducts.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [getProducts.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userInfo = payload
      state.userToken = payload.userToken
    },
    [getProducts.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
})
export default productSlice.reducer
import React from "react";
import "./DeleteMenu.scss";
import { Typography, Modal, Input, Form, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import useConfirmMenuModal from "../../hooks/useConfirmMenuModal";

export default function DeleteMenu({
  title,
  isOpened,
  subTitle,
  onOk,
  onCancel,
  afterResponse
}) {
  const { modal, button, text, token } = useConfirmMenuModal();
  return (
    <Modal
      centered
      closeIcon={false}
      className="deletemenu-cont"
      onOk={() => {
        onOk();
        if (!afterResponse) {
            onCancel();
        }
      }}
      okText={"Yes"}
      onCancel={onCancel}
      title={
        <div className="deletemenu-cont__titleWrapper">
          <DeleteOutlined
            style={{
              color: `${token?.colorErrorText}`,
            }}
            className="deletemenu-cont__titleWrapper__icon"
          />
          <Typography.Text className="deletemenu-cont__titleWrapper__title">
            {title}
          </Typography.Text>
        </div>
      }
      cancelText="No"
      cancelButtonProps={{
        size: "small",
        className: "deletemenu-cont__btn",
      }}
      okButtonProps={{
        size: "small",
        type: "primary",
        className: "deletemenu-cont__btn",
      }}
      open={isOpened}
      style={{
        borderTop: `2px solid ${button?.colorPrimary}`,
      }}
    >
      <Typography.Text
        className="deletemenu-cont__subTitle"
        style={{
          color: `${text.titleColor}`,
        }}
      >
        {subTitle}
      </Typography.Text>
    </Modal>
  );
}

import { theme } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetMetrcStrainError } from "../features/metrics/MetrcStrains/metrcStrainsSlice";

export default function useMetrcStrainModal(isOpened, onCancel) {
  const { useToken } = theme;
  const { token } = useToken();
  const dispatch = useDispatch();
  const { loading, error, success } = useSelector(
    (state) => state?.metrcStrains
  );
  const formRef = useRef(null);
  const [formData, setFormData] = useState([]);
  const [value, setValue] = useState(0);
  const [isTouchedOnSLider, setIsTouchedOnSlider] = useState(false);

  const onSLiderChange = (value) => {
    setIsTouchedOnSlider(true);
    setValue(value);
    if (error && error["indica_percentage"]) {
      dispatch(resetMetrcStrainError("indica_percentage"));
    }
    if (error && error["sativa_percentage"]) {
      dispatch(resetMetrcStrainError("sativa_percentage"));
    }
  };

  useEffect(() => {
    if (isOpened) {
      if (error && !loading) {
        for (let key in error) {
          if (key === "sativa_percentage" || key === "indica_percentage") {
            formRef?.current?.setFields([
              {
                name: "slider", // Field name you want to set the error for
                errors: [error[key][0]], // Assuming your error has a 'message' field
              },
            ]);
          } else {
            formRef?.current?.setFields([
              {
                name: key, // Field name you want to set the error for
                errors: [error[key][0]], // Assuming your error has a 'message' field
              },
            ]);
          }
        }
      }
    }
  }, [loading, error]);

  useEffect(() => {
    if (isOpened) {
      if (success) {
        formRef.current.resetFields();
        dispatch(resetMetrcStrainError());
        setValue(0);
        setIsTouchedOnSlider(false);
        onCancel();
      }
    }
  }, [success, dispatch]);

  return {
    token,
    loading,
    error,
    formRef,
    dispatch,
    resetMetrcStrainError,
    formData,
    value,
    setValue,
    onSLiderChange,
    isTouchedOnSLider,
    setIsTouchedOnSlider,
  };
}

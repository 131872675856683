import React from "react";
import "./LandingSpin.scss";

export default function LandingSpin({
  size = 40,
  color = "#61BC18",
  spinning = true,
  secondColor="rgba(173,62,240,255)"
}) {
  return (
    spinning && (
      <div className="spin-container">
        <div
          className="spinner"
          style={{
            width: size,
            height: size,
            borderColor: `${color} transparent ${secondColor} transparent`,
          }}></div>
      </div>
    )
  );
}

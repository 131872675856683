import { createSlice } from "@reduxjs/toolkit";
import { getMetrcReceipts, getMetrcReceiptsSync } from "./metrcSalesActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  sales: {},
};

const metrcSalesSlice = createSlice({
  name: "metrcSales",
  initialState,
  reducers: {
    resetMetrcSaleError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: {
    [getMetrcReceipts.pending]: (state) => {
      state.loading = true;
    },
    [getMetrcReceipts.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.sales = payload;
    },
    [getMetrcReceipts.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getMetrcReceiptsSync.pending]: (state) => {
      state.loading = true;
    },
    [getMetrcReceiptsSync.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [getMetrcReceiptsSync.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { resetMetrcSaleError } = metrcSalesSlice.actions;
export default metrcSalesSlice.reducer;

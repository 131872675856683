import React from "react";
import "./InventoryModal.scss";
import {
  Button,
  Descriptions,
  Form,
  Modal,
  Table,
  Typography,
  Select,
  Input,
} from "antd";
import useMetrcInventoryModal from "../../hooks/useMetrcInventoryModal";
import AutoFillModal from "./AutoFillModal";
import Utils from "../../utils";
import { useDispatch } from "react-redux";
import { adjustPackages } from "../../features/metrics/metricActions";

const reasons = ["Damage", "Incorrect Quantity", "Mandated Destruction", "Oversold", "Public Safety/Recall", "Regulatory Test Sample", "Scale Variance", "State-Authorized Adjustment", "Theft", "Trade Sample", "Undersold", "Voluntary Surrender", "Waste (Unusable Product)"]

export default function InventoryModal({
  isOpened,
  title,
  onOk,
  onCancel,
  subTitile,
  data,
  adjustmentList,
  selectedAdjustment,
  api
}) {
  const {
    token,
    formRef,
    formData,
    tableColumns,
    width,
    isAutoFillModalOPened,
    setIsAutofillModalOpened,
    autoFillFormRef,
    onAutoFillSave,
    dispatch
  } = useMetrcInventoryModal(api, data);
  return (
    <Modal
      className="inventoryModal-cont"
      style={{
        borderTop: `2px solid ${token?.Button?.colorPrimary}`,
      }}
      open={isOpened}
      title={title}
      centered={true}
      okText="Save"
      closeIcon={false}
      cancelButtonProps={{
        className: "inventoryModal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        className: "inventoryModal-cont__btn",
        htmlType: "submit",
        type: "primary",
        key: "submit",
        // loading: loading && isOpened,
        // disabled: error ? Object.keys(error)?.length !== 0 : false,
      }}
      onCancel={() => {
        onCancel();
        formRef?.current?.resetFields();
        // if (error) {
        //   dispatch(resetMetrcInventoryError());
        // }
      }}
      onOk={() => {
        formRef?.current?.submit();
      }}>
      <div className="inventoryModal-cont__subTitleWrapper">
        <Typography.Text className="inventoryModal-cont__subTitleWrapper__text">
          {subTitile}
        </Typography.Text>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            setIsAutofillModalOpened(true);
          }}>
          Auto-fill
        </Button>
      </div>
      <Form
        className="inventoryModal-cont__form"
        ref={formRef}
        layout="vertical"
        onFinish={(val) => {
          let ids = [];
          let res = [];
          for (let key in val) {
            let id = key?.split(" ")[0];
            if (!ids?.includes(id)) {
              ids?.push(id);
              res?.push({
                adjustment_reason: val[`${id} reason`],
                reason_note: val[`${id} note`],
                quantity: adjustmentList[id],
                label: val[`${id} label`],
                unit_of_measure: val[`${id} unit`],
                metrc_adjustment_option: selectedAdjustment,
                adjustment_date: Utils.getMetricFormattedDate(new Date())
              });
            }
          }

          dispatch(adjustPackages(res)).then((data) => {
            if (data && data.payload) {
              if (data.meta.requestStatus === "fulfilled") {
                onOk();
                onCancel();
                formRef?.current?.resetFields();
              } else {
                
              }
            }
          })

        }}
        onFinishFailed={(error) => {
          console.log("onFinishFailed", error);
        }}
        fields={formData}>
        {data?.filter(el => adjustmentList[el.id]).map((el, i) => (
          <React.Fragment key={el?.id}>
            <div className="inventoryModal-cont__form__itemNameWrapper">
              <Typography.Text className="inventoryModal-cont__form__itemNameWrapper__text">
                {el?.item_name}
              </Typography.Text>
            </div>
            <div className="inventoryModal-cont__form__formItemWrapper">
              <Form.Item
                name={`${el?.id} unit`}
                initialValue={el?.item?.unit_of_measure_name}
              >
                <Input type="hidden" value={el?.item?.unit_of_measure_name} />
              </Form.Item>
              <Form.Item
                name={`${el?.id} label`}
                initialValue={el?.label}
              >
                <Input type="hidden" value={el?.label} />
              </Form.Item>
              <Form.Item
                className="inventoryModal-cont__form__formItemWrapper__formItem"
                name={`${el?.id} inventory`}
                colon={false}>
                <div className="inventoryModal-cont__form__formItemWrapper__formItem__infoWarpper">
                  <Table
                    className="inventoryModal-cont__form__formItemWrapper__formItem__infoWarpper__table"
                    bordered
                    columns={tableColumns}
                    dataSource={[{ ...el, expected: adjustmentList[el.id], difference: (adjustmentList[el.id] - Number(el?.quantity)) + "" + el?.unit_of_measure_abbreviation }]}
                    pagination={false}
                    scroll={{
                      x: "450px",
                    }}
                  />

                  <Descriptions
                    className="inventoryModal-cont__form__formItemWrapper__formItem__infoWarpper__descriptions"
                    items={[
                      {
                        key: "reason",
                        label: <Typography.Text strong>Reason</Typography.Text>,
                        children: (
                          <Form.Item
                            //   className="MetrcPurchaseOrderHeader-cont__orderDeatilWrapper__form__descriptionWrapper__description__formItem"
                            name={`${el?.id} reason`}
                            rules={[
                              {
                                required: true,
                                message: "Please select Reason",
                              },
                            ]}
                            colon={false}>
                            <Select
                              placeholder="Reason"
                              allowClear
                              options={reasons.map(reason => {
                                return {
                                  label: reason,
                                  value: reason,
                                }
                              })}
                            />
                          </Form.Item>
                        ),
                      },
                      // {
                      //   key: "metrc_reason",
                      //   label: (
                      //     <Typography.Text strong>Metrc Reason</Typography.Text>
                      //   ),
                      //   children: (
                      //     <Form.Item
                      //       //   className="MetrcPurchaseOrderHeader-cont__orderDeatilWrapper__form__descriptionWrapper__description__formItem"
                      //       name={`${el?.batch_id} metrc_reason`}
                      //       rules={[
                      //         {
                      //           required: true,
                      //           message: "Please select Metrc reason",
                      //         },
                      //       ]}
                      //       colon={false}>
                      //       <Select
                      //         placeholder="Metrc reason"
                      //         allowClear
                      //         options={[
                      //           {
                      //             label: "Metrc reason",
                      //             value: "metrc_reason",
                      //           },
                      //         ]}
                      //       />
                      //     </Form.Item>
                      //   ),
                      // },
                      {
                        key: "note",
                        label: <Typography.Text strong>Note</Typography.Text>,
                        children: (
                          <Form.Item
                            name={`${el?.id} note`}
                            rules={[
                              {
                                required: true,
                                message: "Please enter note",
                              },
                            ]}
                            colon={false}>
                            <Input placeholder="Note" />
                          </Form.Item>
                        ),
                      },
                    ]}
                    colon={false}
                    column={1}
                    size="small"
                    bordered
                    layout={width < 600 ? "vertical" : "horizontal"}
                  />
                </div>
              </Form.Item>
            </div>
          </React.Fragment>
        ))}
      </Form>
      <AutoFillModal
        reasons={reasons}
        title={"Auto Fill Items"}
        isOpened={isAutoFillModalOPened}
        formRef={autoFillFormRef}
        onCancel={() => {
          setIsAutofillModalOpened(false);
        }}
        onOk={onAutoFillSave}
      />
    </Modal>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import {
  getTeamMembers,
  patchTeamMember,
  postTeamMember,
} from "./teamMembersAction";

const initialState = {
  loading: false,
  error: null,
  success: false,
  teamUsers: {},
};

const teamMembersSlice = createSlice({
  name: "teamMembers",
  initialState,
  reducers: {
    resetTeamMemberError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: {
    [getTeamMembers.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getTeamMembers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.teamUsers = payload;
      state.error = null;
    },
    [getTeamMembers.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [patchTeamMember.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [patchTeamMember.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const updatedTeamUsers = state?.teamUsers?.results?.map((el) =>
        el?.id === payload?.id ? payload : el
      );
      state.teamUsers = {
        meta: state.teamUsers?.meta,
        results: updatedTeamUsers,
      };
      state.error = null;
      state.success = true;
    },
    [patchTeamMember.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [postTeamMember.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [postTeamMember.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.teamUsers = {
        meta: state?.teamUsers?.meta,
        results: [payload, ...state?.teamUsers?.results],
      };
      state.error = null;
      state.success = true;
    },
    [postTeamMember.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
export const { resetTeamMemberError } = teamMembersSlice.actions;
export default teamMembersSlice.reducer;

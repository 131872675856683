import { createSlice } from "@reduxjs/toolkit";
import { getLocations } from "./locationAction";

const initialState = {
  loading: false,
  error: null,
  success: false,
  possibleLocations: [],
};

const locationSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {},
  extraReducers: {
    [getLocations.pending]: (state) => {
      state.loading = true;
    },
    [getLocations.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.possibleLocations = payload;
      state.error = null;
    },
    [getLocations.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
export default locationSlice.reducer;

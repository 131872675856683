import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import axiosClient from "../../common/api/request";

export const getItems = createAsyncThunk(
  "orders",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/orders/`,
        { params },
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const updateOrderDriver = createAsyncThunk(
  "updateOrderDriver",
  async (params, { rejectWithValue }) => {
    const { id, ...networkParams } = params;
    try {
      const data = await axiosClient.patch(
        `${config.API_URL}/orders/${params.id}/`,
        networkParams,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const updateOrderStatus = createAsyncThunk(
  "updateOrderStatus",
  async (params, { rejectWithValue }) => {
    const { id, ...networkParams } = params;
    try {
      const data = await axiosClient.patch(
        `${config.API_URL}/orders/${params.id}/`,
        networkParams,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const getOrderItems = createAsyncThunk(
  "orderItems",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(`${config.API_URL}/orderitems/`, {
        params,
      });
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const updateOrderPaymentType = createAsyncThunk(
  "updateOrderPaymentType",
  async (params, { rejectWithValue }) => {
    const { id, ...networkParams } = params;
    try {
      const data = await axiosClient.patch(
        `${config.API_URL}/orders/${params.id}/`,
        networkParams,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const calculateOrder = createAsyncThunk(
  "calculateOrder",
  async (params, { rejectWithValue }) => {
    const { id, ...networkParams } = params;
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/orders/calculate/`,
        networkParams,
        config
      );
      return { ...data, id };
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const postOrder = createAsyncThunk(
  "postOrder",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/orders/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const updateGrandTotal = createAsyncThunk(
  "updateGrandTotal",
  async (params, { rejectWithValue }) => {
    const { id, ...networkParams } = params;
    try {
      const data = await axiosClient.patch(
        `${config.API_URL}/orders/${params.id}/`,
        networkParams,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const patchOrder = createAsyncThunk(
  "updateOrder",
  async (params, { rejectWithValue }) => {
    const { id, ...networkParams } = params;
    try {
      const data = await axiosClient.patch(
        `${config.API_URL}/orders/${params.id}/`,
        networkParams,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const patchOrderItems = createAsyncThunk(
  "updateOrderItems",
  async (params, { rejectWithValue }) => {
    const { id, ...networkParams } = params;
    try {
      const data = await axiosClient.patch(
        `${config.API_URL}/orders/${params.id}/`,
        networkParams,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const patchOrderPromotionCode = createAsyncThunk(
  "update_order_promotionCode",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.patch(
        `${config.API_URL}/orders/${params?.id}/`,
        {
          discount: params?.discount?.id ? params?.discount?.id : null,
        },
        config
      );
      return {
        ...data,
        discount: params?.discount,
      };
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const getOrderRooms = createAsyncThunk(
  "get_order_rooms",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/order_rooms/`,
        { params },
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const getRoomOrders = createAsyncThunk(
  "get_order_room_orders",
  async ({ id, ...params }, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/order_rooms/${id}/orders/`,
        { params },
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

export const reportToMetric = createAsyncThunk(
  "sync_with_metric",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/orders/report_to_metrc/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const postOrderRoom = createAsyncThunk(
  "post_order_room",
  async ({ id, ...params }, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/order_rooms/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const patchOrderRoom = createAsyncThunk(
  "patch_order_room",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.patch(
        `${config.API_URL}/order_rooms/${params?.id}/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const deleteOrderRoom = createAsyncThunk(
  "delete_order_room",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.delete(
        `${config.API_URL}/order_rooms/${params.id}/`,
        params,
        config
      );
      return {
        data,
        id: params?.id,
      };
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

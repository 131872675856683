import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../../config";
import axiosClient from "../../../common/api/request";

export const getMetrcItems = createAsyncThunk(
  "get_metrc_items",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/metrcs/items/`,
        { params },
        config
      );
      return data;
    } catch (error) {
      if (
        error?.response &&
        error.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

export const getMetrcItemsSync = createAsyncThunk(
  "get_metrc_items_sync",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/metrcs/items/sync/`,
        { params },
        config
      );
      return data;
    } catch (error) {
      if (
        error?.response &&
        error.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
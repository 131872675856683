import { createSlice } from "@reduxjs/toolkit";
import { getDiscounts } from "./discountsAction";

const initialState = {
  loading: false,
  error: null,
  success: false,
  possibleDiscounts: [],
};

const discountsSlice = createSlice({
  name: "discounts",
  initialState,
  reducers: {},
  extraReducers: {
    [getDiscounts.pending]: (state) => {
      state.loading = true;
    },
    [getDiscounts.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.possibleDiscounts = payload;
      state.error = null;
    },
    [getDiscounts.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
export default discountsSlice.reducer;

import React from "react";
import "./RoleModal.scss";
import {
  Modal,
  Checkbox,
  Form,
  Typography,
  Input,
  // Divider,
  // Button,
  Tree,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import useRoleModal from "../../hooks/useRoleModal";
const { Search } = Input;
export default function RoleModal({ isOpened, title, onOk, onCancel, group }) {
  const {
    token,
    selecTedPermisssions,
    setSelectedPermissions,
    formRef,
    formData,
    permissions,
    loading,
    error,
    dispatch,
    resetGroupError,
    handleFildChanged,
    onCheckAllChange,
    checkAll,
    indeterminate,
    searchValue,
    setSearchValue,
    onCheck,
    onExpand,
    expandedKeys,
    setExpandedKeys,
    selectedPermissionsByParent,
    makeEmptySelectedPermissionsByParent,
    setSelectedPermissionsByParent,
    onSearchBarchange,
    filteredeTreeData,
    indeterminateForExpansion,
    expandAll,
    onCheckAllExpandChange,
  } = useRoleModal(group, onCancel, isOpened);
  const partLength = Math?.ceil(filteredeTreeData?.length / 3);
  // Slice the array into three equal parts
  const firstPart = filteredeTreeData?.slice(0, partLength);
  const secondPart = filteredeTreeData?.slice(partLength, partLength * 2);
  const thirdPart = filteredeTreeData?.slice(partLength * 2);

  return (
    <Modal
      className="roleModal-cont"
      open={isOpened}
      title={
        <>
          {title}
          <Form
            className="roleModal-cont__form"
            ref={formRef}
            layout="vertical"
            onFinish={(values) => {
              if (group) {
                if (
                  handleFildChanged(
                    group?.permissions,
                    selecTedPermisssions,
                    values?.label
                  )
                ) {
                  onOk({
                    label: values?.label,
                    permissions: selecTedPermisssions,
                  });
                } else {
                  onCancel();
                  setSelectedPermissions([]);
                  formRef.current.resetFields();
                  dispatch(resetGroupError());
                  setSearchValue("");
                  setExpandedKeys([...Object.keys(permissions)]);
                  setSelectedPermissionsByParent(
                    makeEmptySelectedPermissionsByParent(selectedPermissionsByParent)
                  );
                }
              } else {
                onOk({
                  label: values?.label,
                  permissions: selecTedPermisssions,
                });
              }
            }}
            onFinishFailed={(error) => {
            }}
            fields={formData}
          >
            <Form.Item
              className="roleModal-cont__form__formItem"
              name="label"
              rules={[
                {
                  required: true,
                  message: "Please enter your role name",
                },
              ]}
              label={
                <Typography.Text className="roleModal-cont__form__formItem__text">
                  Group name
                </Typography.Text>
              }
              colon={false}
            >
              <Input
                type="text"
                placeholder="Group name"
                onChange={() => {
                  if (error && error["label"]) {
                    dispatch(resetGroupError("label"));
                  }
                }}
              />
            </Form.Item>
            <div className="roleModal-cont__form__formItemWrapper">
              <Form.Item className="roleModal-cont__form__formItemWrapper__formItem">
                <Search
                  placeholder="Search by title"
                  value={searchValue}
                  onChange={(e) => {
                    onSearchBarchange(e.target.value);
                  }}
                />
              </Form.Item>
              <div
                style={{
                  display: "flex",
                  gap: "4px",
                }}
              >
                <Form.Item name="All">
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                  >
                    Check all
                  </Checkbox>
                </Form.Item>
                <Form.Item name="Expand_All">
                  <Checkbox
                    indeterminate={indeterminateForExpansion}
                    onChange={onCheckAllExpandChange}
                    checked={expandAll}
                  >
                    Expand all
                  </Checkbox>
                </Form.Item>
              </div>
            </div>
          </Form>
        </>
      }
      centered={true}
      okText="Save"
      closeIcon={false}
      width={850}
      onOk={() => {
        if (error) {
          dispatch(resetGroupError());
        }
        formRef.current.submit();
      }}
      onCancel={() => {
        onCancel();
        setSelectedPermissions([]);
        formRef.current.resetFields();
        dispatch(resetGroupError());
        setSearchValue("");
        setExpandedKeys([...Object.keys(permissions)]);
        setSelectedPermissionsByParent(
          makeEmptySelectedPermissionsByParent(selectedPermissionsByParent)
        );
      }}
      bodyStyle={{
        overflowY: "auto",
        maxHeight: "350px",
      }}
      style={{
        borderTop: `2px solid ${token?.Button?.colorPrimary}`,
      }}
      cancelButtonProps={{
        className: "roleModal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        className: "roleModal-cont__btn",
        type: "primary",
        loading: loading,
        disabled: error ? Object.keys(error)?.length !== 0 : false,
      }}
    >
      <div className="roleModal-cont__treeCont">
        <div>
          {firstPart?.map((parent) => (
            <Tree
              key={parent?.key}
              showLine
              switcherIcon={<DownOutlined />}
              expandedKeys={expandedKeys}
              checkedKeys={
                permissions?.length === selecTedPermisssions?.length
                  ? [...selectedPermissionsByParent[parent?.key], parent?.key]
                  : selectedPermissionsByParent[parent?.key]
              }
              checkable
              onCheck={(checkedKeys) => {
                onCheck(checkedKeys, parent?.key);
              }}
              treeData={[parent]}
              selectable={false}
              autoExpandParent={true}
              onExpand={() => {
                onExpand(parent?.key);
              }}
            />
          ))}
        </div>
        <div>
          {secondPart?.map((parent) => (
            <Tree
              key={parent?.key}
              showLine
              switcherIcon={<DownOutlined />}
              expandedKeys={expandedKeys}
              checkedKeys={
                permissions?.length === selecTedPermisssions?.length
                  ? [...selectedPermissionsByParent[parent?.key], parent?.key]
                  : selectedPermissionsByParent[parent?.key]
              }
              checkable
              onCheck={(checkedKeys) => {
                onCheck(checkedKeys, parent?.key);
              }}
              treeData={[parent]}
              selectable={false}
              autoExpandParent={true}
              onExpand={() => {
                onExpand(parent?.key);
              }}
            />
          ))}
        </div>
        <div>
          {thirdPart?.map((parent) => (
            <Tree
              key={parent?.key}
              showLine
              switcherIcon={<DownOutlined />}
              expandedKeys={expandedKeys}
              checkedKeys={
                permissions?.length === selecTedPermisssions?.length
                  ? [...selectedPermissionsByParent[parent?.key], parent?.key]
                  : selectedPermissionsByParent[parent?.key]
              }
              checkable
              onCheck={(checkedKeys) => {
                onCheck(checkedKeys, parent?.key);
              }}
              treeData={[parent]}
              selectable={false}
              autoExpandParent={true}
              onExpand={() => {
                onExpand(parent?.key);
              }}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
}

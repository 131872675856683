import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import axiosClient from "../../common/api/request";

export const getDrivers = createAsyncThunk(
  "drivers",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL_V2}/drivers/`,
        { params },
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const postDriver = createAsyncThunk(
  "postDriver",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL_V2}/drivers/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const updateDriver = createAsyncThunk(
  "updateDriver",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.patch(
        `${config.API_URL_V2}/drivers/${params.id}/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const deleteDriver = createAsyncThunk(
  "deleteDriver",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.delete(
        `${config.API_URL_V2}/drivers/${params.id}/`,
        params,
        config
      );
      return {
        data,
        id: params?.id,
      };
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

import React from "react";
import "./SiteHeader.scss";
import Logo from "../../assets/LogoWithoutText.svg";
import LogoWithText from "../../assets/logo.svg";
import classNames from "classnames";
import { ExportOutlined, NotificationOutlined } from "@ant-design/icons";
import {
  Layout,
  Space,
  Typography,
  Image,
  Avatar,
  Badge,
  Popover,
  Button,
} from "antd";
import {
  MenuFoldOutlined,
  BellOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import useNavigation from "../../hooks/useNavigation";
import { ConfirmModal } from "../../modals";
import { Link } from "react-router-dom";

const { Sider, Header } = Layout;
export default function SiteHeader({
  isMenuClosed,
  setisMenuClosed,
  setIsChangePasswordOpened,
}) {
  const {
    onMenuClick,
    navigate,
    token,
    isPopoverOpened,
    setIsPopOverOpened,
    isNotificationsOpened,
    setNotificationsOpened,
    user,
    isLogoutModalOpened,
    setIsLogOutModalOpened,
    handleLogOut,
    isMobile,
    popoverContent,
    notificationList,
    socketGeneratedReports,
    socketData,
  } = useNavigation(isMenuClosed, setisMenuClosed, setIsChangePasswordOpened);

  return (
    <Layout className="header-cont">
      {isMobile ? null : (
        <Sider
          trigger={null}
          collapsible
          collapsed={isMenuClosed}
          className={classNames("header-cont__logo-wrapper", {
            menuClosed: isMenuClosed,
          })}>
          <div className={"header-cont__logo-wrapper__img-wrapper"}>
            {isMenuClosed ? (
              <Link to="/admin">
                <Image
                  src={Logo}
                  alt="Canasale"
                  preview={false}
                  style={{
                    width: 35,
                    height: 40,
                  }}
                />
              </Link>
            ) : (
              <Link to="/admin">
                <Image
                  src={LogoWithText}
                  alt="Canasale"
                  preview={false}
                  style={{
                    width: 170,
                  }}
                />
              </Link>
            )}
          </div>
        </Sider>
      )}
      <Layout>
        <Header
          className="header-cont__header"
          style={
            isMobile
              ? null
              : {
                  borderColor: `${token?.Header?.saitHeaderBorder}`,
                  boxShadow: `${token?.Header?.boxShadow}`,
                }
          }>
          <Space direction="horizontal" align="center" size={16}>
            {isMenuClosed ? (
              <MenuUnfoldOutlined
                onClick={onMenuClick}
                className="header-cont__header__MenuFoldOutlined"
                style={{
                  color: `${token?.icons?.whiteIcon}`,
                }}
              />
            ) : (
              <MenuFoldOutlined
                className="header-cont__header__MenuFoldOutlined"
                onClick={onMenuClick}
                style={{
                  color: `${token?.icons?.whiteIcon}`,
                }}
              />
            )}
            {isMobile ? (
              <Image
                src={LogoWithText}
                preview={false}
                alt="CanaSale"
                className="header-cont__header__img"
              />
            ) : (
              <Typography.Text
                className="header-cont__header__text"
                style={{
                  color: `${token?.Typography?.whieteColorText}`,
                }}>
                Welcome back, {user?.organization?.name} 🍀
              </Typography.Text>
            )}
          </Space>
          <Space direction="horizontal" align="start" size={20}>
            <Popover
              open={isNotificationsOpened}
              trigger="click"
              className="header-cont__popover"
              content={
                notificationList?.length ? (
                  notificationList
                ) : (
                  <div className="header-cont__popover__empty">
                    <NotificationOutlined style={{ fontSize: "20px" }} />
                    No Notifications
                  </div>
                )
              }
              onOpenChange={() => {
                setNotificationsOpened(!isNotificationsOpened);
              }}
              title={"Notifications"}>
              <Badge
                dot={
                  socketData?.length || socketGeneratedReports?.length
                    ? true
                    : false
                }
                size="small">
                <BellOutlined
                  className="header-cont__header__BellOutlined"
                  onClick={() => setNotificationsOpened(true)}
                  style={{
                    color: `${token?.icons?.whiteIcon}`,
                  }}
                />
              </Badge>
            </Popover>
            <Popover
              open={isPopoverOpened}
              trigger="click"
              className="header-cont__popover"
              content={popoverContent}
              onOpenChange={() => {
                setIsPopOverOpened(!isPopoverOpened);
              }}
              title={
                <Button
                  type="text"
                  className="header-cont__popover__btn"
                  onClick={() => {
                    setIsPopOverOpened(false);
                    navigate("myaccount");
                  }}>
                  {`${user?.first_name} ${user?.last_name}`}
                </Button>
              }>
              <Badge size="small">
                <Avatar
                  shape="square"
                  style={{
                    cursor: "pointer",
                    backgroundColor: token?.Header?.saitHeaderBorder,
                  }}
                  onClick={() => {
                    setIsPopOverOpened(true);
                  }}
                  size={24}
                  icon={
                    <UserOutlined
                      className="header-cont__header__UserOutlined"
                      style={{
                        color: `${token?.icons?.userIcon}`,
                      }}
                    />
                  }
                />
              </Badge>
            </Popover>
          </Space>
        </Header>
      </Layout>
      <ConfirmModal
        isOpened={isLogoutModalOpened}
        title={"Are you sure"}
        subTitle={"You want to log out ?"}
        icon={<ExportOutlined style={{ fontSize: "24px" }} />}
        onCancel={() => {
          setIsLogOutModalOpened(false);
        }}
        onOk={handleLogOut}
      />
    </Layout>
  );
}

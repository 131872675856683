import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import {
  MenuDashBoard,
  Team,
  Listings,
  Orders,
  Patient,
  Reports,
  Settings,
  Menu,
  Safe,
  MyAccount,
  Landing,
  BlogArchive,
  SingleBlog,
  MainLanding,
  Metrics,
  Terms,
  Privacy,
  MapContainer,
  GeneratedReports,
  PromoCodes,
} from "../pages";
import { Drivers, Roles, TeamMembers } from "../pages/Team/components";
import "./App.scss";
import useApp from "../hooks/useApp";
import { theme } from "../config/theme";
import Can from "../common/services/permissions/Can";
import {
  AllDrivers,
  Bags,
  AddBag,
} from "../pages/Team/components/Drivers/Components";
import { CreateOrder } from "../pages/Orders/components";
import { CreateMenuItem, EditMenuItem } from "../pages/Menu/pagescomponents";
import {
  TopSoldProducts,
  DynamicReport,
  DailyOrders,
} from "../pages/Reports/components";
import { AddPromotion, PromoCodesData } from "../pages/PromoCodes/Components";

const Packages = React.lazy(() => import("../pages/Metrics/components/Packages/Packages"));
const Sales = React.lazy(() => import("../pages/Metrics/components/Sales/Sales"));
const TaskManager = React.lazy(() => import("../pages/Metrics/components/TaskManager/TaskManager"));
const Transactions = React.lazy(() => import("../pages/Metrics/components/Transactions/Transactions"));
const MetrcItems = React.lazy(() => import("../pages/Metrics/components/Items/MetrcItems"));
const MetrcStrains = React.lazy(() => import("../pages/Metrics/components/Strains/MetrcStrains"));
const SyncJobs = React.lazy(() => import("../pages/Metrics/components/SyncJobs/SyncJobs"));
const RetailDeliveries = React.lazy(() => import("../pages/Metrics/components/RetailDeliveries/RetailDeliveries"));
const MetrcTags = React.lazy(() => import("../pages/Metrics/components/Tags/MetrcTags"));
const ActivePo = React.lazy(() => import("../pages/Metrics/components/ActivePo/ActivePo"));
const ViewMetrcOrder = React.lazy(() => import("../pages/Metrics/components/ViewMetrcOrder/ViewMetrcOrder"));
const MetrcCategories = React.lazy(() => import("../pages/Metrics/components/Categories/MetrcCategories"));
const Transfers = React.lazy(() => import("../pages/Metrics/components/ComplianceTransfers/Transfers"));
const ArchivePo = React.lazy(() => import("../pages/Metrics/components/ArchivePo/ArchivePo"));
const CreatePurchaseOrder = React.lazy(() => import("../pages/Metrics/components/CreatePurchaseOrder/CreatePurchaseOrder"));
const Reconciliation = React.lazy(() => import("../pages/Metrics/components/Reconciliation/Reconciliation"));
const Chat = React.lazy(() => import("../pages/Chat/Chat"));
const InventoryTransfers = React.lazy(() => import("../pages/Metrics/components/InventoryTransfers/InventoryTransfers"));


function App() {
  const { me } = useApp();
  return (
    <div className="App">
      <ConfigProvider theme={theme}>
        <Routes>
          {/* this is can component as wen need we can leave comments */}
          <Route
            path="/"
            element={
              <Can I={"view"} a={"page"} withRedirect={true} login={false}>
                <Suspense fallback={<></>}><Landing /></Suspense>
              </Can>
            }>
            <Route index element={<Suspense fallback={<></>}><MainLanding /></Suspense>} />
            <Route path="login" element={<Suspense fallback={<></>}><MainLanding /></Suspense>} />
            <Route path="archive" element={<Suspense fallback={<></>}><BlogArchive /></Suspense>} />
            <Route path="blog/:url" element={<Suspense fallback={<></>}><SingleBlog /></Suspense>} />
            <Route path="privacypolicy" element={<Suspense fallback={<></>}><Privacy /></Suspense>} />
            <Route path="terms" element={<Suspense fallback={<></>}><Terms /></Suspense>} />
          </Route>

          <Route
            path="/admin"
            element={
              <Can I={"view"} a={"page"} withRedirect={true} login={true}>
                <Suspense fallback={<></>}><MenuDashBoard /></Suspense>
              </Can>
            }>
            {/* <Route path="/" element={<MenuDashBoard />}> */}
            <Route
              index
              element={
                <Can I="view" a="menu" withRedirect={true}>
                  <Suspense fallback={<></>}><Menu /></Suspense>
                </Can>
              }
            />
            <Route
              path="safe"
              element={
                <Can I="view" a="menu" withRedirect={true}>
                  <Suspense fallback={<></>}><Safe /></Suspense>
                </Can>
              }
            />
            <Route
              path="menu/:menuId/:subMenuId?"
              element={
                <Can I="view" a="menu" withRedirect={true}>
                  <Suspense fallback={<></>}><Menu /></Suspense>
                </Can>
              }
            />
            <Route
              path="addmenuitem/:menuId?/:subMenuId?/:externalId?"
              element={
                <Can I="add" a="menuitem" withRedirect={true}>
                   <Suspense fallback={<></>}><CreateMenuItem /></Suspense>
                </Can>
              }
            />
            <Route
              path="menu-item/:menuId?/:subMenuId?/:productId?"
              element={
                <Can I="change" a="menuitem" withRedirect={true}>
                 <Suspense fallback={<></>}> <EditMenuItem /></Suspense>
                </Can>
              }
            />
            <Route path="team" element={<Suspense fallback={<></>}><Team /></Suspense>}>
              <Route
                index
                element={
                  <Can I="view" a="driver" withRedirect={true}>
                    <Suspense fallback={<></>}><Drivers /></Suspense>
                  </Can>
                }
              />
              <Route
                path="drivers"
                element={
                  <Can I="view" a="driver" withRedirect={true}>
                    <Suspense fallback={<></>}><Drivers /></Suspense>
                  </Can>
                }>
                <Route
                  path="all"
                  element={
                    <Can I="view" a="driver" withRedirect={true}>
                      <Suspense fallback={<></>}><AllDrivers /></Suspense>
                    </Can>
                  }
                />
                <Route
                  path="bags"
                  element={
                    <Can I="view" a="driverbag" withRedirect={true}>
                      <Suspense fallback={<></>}><Bags /></Suspense>
                    </Can>
                  }
                />
                {/* <Route path="addbag" element={<Suspense fallback={<></>}><AddBag /></Suspense>} /> */}
                <Route
                  path="editbag/:bagId"
                  element={
                    <Can I="change" a="driverbag" withRedirect={true}>
                      {" "}
                      <Suspense fallback={<></>}><AddBag /></Suspense>
                    </Can>
                  }
                />
              </Route>
              <Route
                path="teammemebers"
                element={
                  <Can I="view" a="driver" withRedirect={true}>
                    <Suspense fallback={<></>}><TeamMembers /></Suspense>
                  </Can>
                }
              />
              <Route
                path="groups"
                element={
                  <Can I="view" a="groups" withRedirect={true}>
                    <Suspense fallback={<></>}><Roles /></Suspense>
                  </Can>
                }>
                <Route index element={<Suspense fallback={<></>}><Roles /></Suspense>} />
                {/* <Route path="creategroup" element={<CreateRole />} /> */}
              </Route>
            </Route>
            <Route path="myaccount" element={<Suspense fallback={<></>}><MyAccount /></Suspense>} />
            <Route path="listings" element={<Listings />} />
            <Route path="orders" element={<Suspense fallback={<></>}><Orders /></Suspense>}>
              <Route
                path="/admin/orders/:roomid/createorder"
                element={
                  <Can I="view" a="createorder" withRedirect={true}>
                    <Suspense fallback={<></>}><CreateOrder /></Suspense>
                  </Can>
                }
              />
            </Route>
            <Route path="/admin/orders/:roomid/:patient?" element={<Suspense fallback={<></>}><Orders /></Suspense>} />
            <Route path="promocodes" element={<Suspense fallback={<></>}><PromoCodes /></Suspense>}>
              <Route index element={<Suspense fallback={<></>}><PromoCodesData /></Suspense>} />
              <Route path="add" element={<Suspense fallback={<></>}><AddPromotion /></Suspense>} />
              <Route path=":promoCodeId/edit" element={<Suspense fallback={<></>}><AddPromotion /></Suspense>} />
            </Route>
            <Route
              path="patient"
              element={
                <Can I="view" a="patient" withRedirect={true}>
                  <Suspense fallback={<></>}><Patient /></Suspense>
                </Can>
              }
            />
            <Route
              path="reports"
              element={
                <Can I="view" a="report" withRedirect={true}>
                  <Suspense fallback={<></>}><Reports /></Suspense>
                </Can>
              }>
              <Route path="topsoldproducts" element={<Suspense fallback={<></>}><TopSoldProducts /></Suspense>} />
              <Route path="dailyorders" element={<Suspense fallback={<></>}><DailyOrders /></Suspense>} />
              <Route path=":reportid" element={<Suspense fallback={<></>}><DynamicReport /></Suspense>} />
            </Route>

            <Route
              path="map"
              element={
                <Can I="view" a="in_map">
                  <Suspense fallback={<></>}><MapContainer /></Suspense>
                </Can>
              }
            />
            <Route path="metrc" element={<Suspense fallback={<></>}><Metrics /></Suspense>}>
              <Route index path="packages" element={<Suspense fallback={<></>}><Packages /></Suspense>} />
              <Route path="sales" element={<Suspense fallback={<></>}><Sales /></Suspense>} />
              <Route path="taskmanager" element={<Suspense fallback={<></>}><TaskManager /></Suspense>} />
              <Route path="transactions" element={<Suspense fallback={<></>}><Transactions /></Suspense>} />
              <Route path="items" element={<Suspense fallback={<></>}><MetrcItems /></Suspense>} />
              <Route path="categories" element={<Suspense fallback={<></>}><MetrcCategories /></Suspense>} />
              <Route path="compliancetransfers" element={<Suspense fallback={<></>}><Transfers /></Suspense>} />
              <Route path="strains" element={<Suspense fallback={<></>}><MetrcStrains /></Suspense>} />
              <Route path="syncjobs" element={<Suspense fallback={<></>}><SyncJobs /></Suspense>} />
              <Route path="retaildeliveries" element={<Suspense fallback={<></>}><RetailDeliveries /></Suspense>} />
              <Route path="tags" element={<Suspense fallback={<></>}><MetrcTags /></Suspense>} />
              <Route path="activepo" element={<Suspense fallback={<></>}><ActivePo /></Suspense>} />
              <Route path=":ordertype/:id/view" element={<Suspense fallback={<></>}><ViewMetrcOrder /></Suspense>} />
              <Route path="archivepo" element={<Suspense fallback={<></>}><ArchivePo /></Suspense>} />
              <Route
                path=":ordertype/create"
                element={<Suspense fallback={<></>}><CreatePurchaseOrder /></Suspense>}
              />
              <Route path="reconciliation" element={<Suspense fallback={<></>}><Reconciliation /></Suspense>} />
              <Route path="inventorytransfers" element={<Suspense fallback={<></>}><InventoryTransfers /></Suspense>} />
            </Route>{" "}
            <Route
              path="settings"
              element={
                <Can I="view" a="settings" withRedirect={true}>
                  <Suspense fallback={<></>}><Settings /></Suspense>
                </Can>
              }
            />
            <Route path="generatedreports" element={<Suspense fallback={<></>}><GeneratedReports /></Suspense>} />
            <Route path="chat" element={<Suspense fallback={<></>}><Chat /></Suspense>} />
          </Route>
        </Routes>
      </ConfigProvider>
    </div>
  );
}

export default App;

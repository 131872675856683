import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import axiosClient from "../../common/api/request";
export const getDriversBags = createAsyncThunk(
  "driversBags",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL_V2}/driver_bags/`,
        { params },
        config
      );
      return params?.unassigned ? { unassigned: true, data } : data;
    }  catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const deleteBag = createAsyncThunk(
  "deletebag",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.delete(
        `${config.API_URL_V2}/driver_bags/${params.id}/`,
        params,
        config
      );
      return {
        id: params?.id,
      };
    }  catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const postDriverBag = createAsyncThunk(
  "postDriverBag",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL_V2}/driver_bags/`,
        params,
        config
      );
      return data;
    }  catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const getDriverBag = createAsyncThunk(
  "driverBag",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL_V2}/driver_bags/${params?.id}/`,
        params,
        config
      );
      return data;
    }  catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const patchDriverBag = createAsyncThunk(
  "patchDriverBag",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.patch(
        `${config.API_URL_V2}/driver_bags/${params?.id}/`,
        { name: params.name },
        config
      );
      return data;
    }  catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import axiosClient from "../../common/api/request";

export const getPromoCodes = createAsyncThunk(
  "get_promo_codes",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/promo_codes/`,
        { params },
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

export const getPromoCode = createAsyncThunk(
  "get_promo_code",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/promo_codes/${params?.id}/`,
        // { params },
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

export const postPromoCode = createAsyncThunk(
  "post_promo_code",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/promo_codes/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

export const patchPromoCode = createAsyncThunk(
  "patch_promo_code",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.patch(
        `${config.API_URL}/promo_codes/${params.id}/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

export const deletePromoCode = createAsyncThunk(
  "delete_promo_code",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.delete(
        `${config.API_URL}/promo_codes/${params.id}/`,
        params,
        config
      );
      return {
        data,
        id: params?.id,
      };
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
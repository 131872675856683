import React from "react";
import "./MetrcTagModal.scss";
import { Modal, Form, Typography, Upload, Progress } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import useMetrcTagModal from "../../hooks/useMetrcTagModal";
const { Dragger } = Upload;

export default function MetrcTagModal({ isOpened, title, onOk, onCancel }) {
  const {
    token,
    formRef,
    formData,
    props,
    fileProgress,
    fileUploadError,
    setFile,
    setFileUploadError,
    setFileProgress,
    contextHolder,
  } = useMetrcTagModal(onCancel);
  return (
    <>
      {contextHolder}
      <Modal
        className="metrcTagModal-cont"
        style={{
          borderTop: `2px solid ${token?.Button?.colorPrimary}`,
        }}
        open={isOpened}
        title={title}
        centered={true}
        okText="Save"
        closeIcon={false}
        footer={(_, { OkBtn, CancelBtn }) => <></>}
        // cancelButtonProps={{
        //   className: "metrcTagModal-cont__btn",
        //   size: "small",
        // }}
        // okButtonProps={{
        //   size: "small",
        //   className: "metrcTagModal-cont__btn",
        //   htmlType: "submit",
        //   type: "primary",
        //   key: "submit",
        //   // loading: loading && isOpened,
        //   // disabled: error ? Object.keys(error)?.length !== 0 : false,
        // }}
        onCancel={() => {
          onCancel();
          formRef?.current?.resetFields();
          setFile(null);
          setFileProgress(0);
          setFileUploadError(false);
          // if (error) {
          //   dispatch(resetMetrcItemError());
          // }
        }}
        // onOk={() => {
        //   if (file) {
        //     if (file?.status !== "error") {
        //       formRef?.current?.submit();
        //     }
        //   } else {
        //     formRef?.current?.submit();
        //   }
        // }}
      >
        <Form
          className="metrcTagModal-cont__form"
          ref={formRef}
          layout="vertical"
          onFinish={(values) => {
            console.log("onFinsish", values);
          }}
          onFinishFailed={(error) => {
            console.log("onFinishFailed", error);
          }}
          fields={formData}>
          <div className="metrcTagModal-cont__form__formItemWrapper">
            <Form.Item
              className="metrcTagModal-cont__form__formItemWrapper__formItem"
              name="Files"
              rules={[
                {
                  required: true,
                  message: "Please drop file",
                },
              ]}
              label={
                <Typography.Text className="metrcTagModal-cont__form__formItemWrapper__formItem__text">
                  File
                </Typography.Text>
              }
              colon={false}>
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">Support for a single upload.</p>
              </Dragger>
            </Form.Item>
            {fileProgress > 0 ? (
              <Progress
                percent={fileProgress}
                status={fileUploadError ? "exception" : "success"}
              />
            ) : null}
          </div>
        </Form>
      </Modal>
    </>
  );
}

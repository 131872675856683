import { createSlice } from "@reduxjs/toolkit";
import { getMe, updateMe } from "./meActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  info: {},
};

const meSlice = createSlice({
  name: "me",
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMe.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
      })
      .addCase(getMe.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(updateMe.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMe.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
      })
      .addCase(updateMe.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});
export const { resetError } = meSlice.actions;
export default meSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { adjustPackages, finishPackages, getPackages, inTakePackages, unFinishPackages, getMetrcCategories, metrcCategoriesSync } from "./metricActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  packages: {},
  categories: {}
};

const metricSlice = createSlice({
  name: "metric",
  initialState,
  reducers: {
    resetError: (state, action) => {
      console.log(action?.payload)
      if (action?.payload) {
        console.log(state)
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPackages.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPackages.fulfilled, (state, action) => {
        state.loading = false;
        state.packages = action.payload;
      })
      .addCase(getPackages.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getMetrcCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMetrcCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload;
      })
      .addCase(getMetrcCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(metrcCategoriesSync.pending, (state) => {
        state.loading = true;
      })
      .addCase(metrcCategoriesSync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(metrcCategoriesSync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      
      .addCase(unFinishPackages.pending, (state) => {
        state.loading = true;
      })
      .addCase(unFinishPackages.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(finishPackages.pending, (state) => {
        state.loading = true;
      })
      .addCase(finishPackages.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(inTakePackages.pending, (state) => {
        state.loading = true;
      })
      .addCase(inTakePackages.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(adjustPackages.pending, (state) => {
        state.loading = true;
      })
      .addCase(inTakePackages.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(adjustPackages.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(adjustPackages.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });



  },
});
export const { resetError } = metricSlice.actions;
export default metricSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import {
  getMetrcStrains,
  getMetrcStrainsSync,
  postMetrcStrain,
} from "./metrcStrainsActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  strains: {},
};

const metrcStrainsSlice = createSlice({
  name: "metrcStrains",
  initialState,
  reducers: {
    resetMetrcStrainError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: {
    [getMetrcStrains.pending]: (state) => {
      state.loading = true;
    },
    [getMetrcStrains.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.strains = payload;
    },
    [getMetrcStrains.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getMetrcStrainsSync.pending]: (state) => {
      state.loading = true;
    },
    [getMetrcStrainsSync.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [getMetrcStrainsSync.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [postMetrcStrain.pending]: (state) => {
      state.loading = true;
    },
    [postMetrcStrain.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.strains = {
        meta: state?.strains?.meta,
        results: [payload, ...state?.strains?.results],
      };
    },
    [postMetrcStrain.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { resetMetrcStrainError } = metrcStrainsSlice.actions;
export default metrcStrainsSlice.reducer;

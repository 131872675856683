import { createSlice } from "@reduxjs/toolkit";
import { getBrands, getBrandProducts } from "./brandsActions";

const initialState = {
  loading: false,
  error: null,
  brands: [],
  brandProducts: []
};

const brandsSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    resetProducts(state) {
      state.brandProducts = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBrands.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBrands.fulfilled, (state, action) => {
        state.loading = false;
        state.brands = action.payload;
        state.error = null;
      })
      .addCase(getBrands.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(getBrandProducts.fulfilled, (state, action) => {
        // console.log('acacaca', action.payload);
        state.brandProducts = action.payload;
      })
  },
});
export const { resetProducts } = brandsSlice.actions;
export default brandsSlice.reducer;

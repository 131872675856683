import React from "react";
import "./MoveMenuItemModal.scss";
import { Modal, Form, Typography, Select } from "antd";
import useMoveMenuItemModal from "../../hooks/useMoveMenuItemModal";

export default function MoveMenuItemModal({
  isOpened,
  title,
  onOk,
  onCancel,
  currentMenuId,
  currentSubMenuId,
  setSelectedRowKeys,
  table,
  formRef,
}) {
  const {
    token,
    loading,
    error,
    dispatch,
    selectOptions,
    filterOption,
    resetSafeError,
    safeLoading
  } = useMoveMenuItemModal(
    currentMenuId,
    currentSubMenuId,
    isOpened,
    onCancel,
    setSelectedRowKeys,
    formRef
  );

  return (
    <Modal
      className="moveMenuItemModal-cont"
      open={isOpened}
      title={title}
      centered={true}
      okText="Move"
      width={1000}
      closeIcon={false}
      onOk={() => {
        formRef.current.submit();
        if (error) {
          dispatch(resetSafeError());
        }
      }}
      onCancel={() => {
        onCancel();
        formRef.current.resetFields();
        dispatch(resetSafeError());
      }}
      style={{
        borderTop: `2px solid ${token?.Button?.colorPrimary}`,
      }}
      cancelButtonProps={{
        className: "moveMenuItemModal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        htmlType: "submit",
        type: "primary",
        className: "moveMenuItemModal-cont__btn",
        loading: loading || safeLoading,
        disabled: error ? Object.keys(error)?.length !== 0 : false,
      }}>
      <Form
        className="moveMenuItemModal-cont__form"
        layout="vertical"
        ref={formRef}
        onFinish={(values) => {
          let modifiedValues = {
            items: [],
            sub_menu: "",
          };
          for (let key in values) {
            if (key !== "sub_menu") {
              modifiedValues?.items?.push({
                menu_item: key,
                count: values[key],
              });
            }
          }
          modifiedValues.sub_menu = values?.sub_menu;
          onOk(modifiedValues);
        }}
        onFinishFailed={(error) => {
          console.log("onfinishFailed", error);
        }}>
        <Form.Item
          className="moveMenuItemModal-cont__form__formItem"
          name="sub_menu"
          rules={[
            {
              required: true,
              message: "Please select sub menu",
            },
          ]}
          colon={false}
          label={
            <Typography.Text
              className="moveMenuItemModal-cont__form__formItem__text"
              style={{
                color: `${token?.Modal.titleColor}`,
              }}>
              Sub menu
            </Typography.Text>
          }>
          <Select
            allowClear
            type="text"
            placeholder="Sub menu"
            disabled={loading || safeLoading}
            showSearch
            // mode="multiple"
            onChange={() => {
              if (error && error?.sub_menu) {
                dispatch(resetSafeError("sub_menu"));
              }
            }}
            filterOption={filterOption}
            options={selectOptions}
          />
        </Form.Item>
        <Form.Item>{table}</Form.Item>
      </Form>
    </Modal>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import {
  getPermissions,
  getGroups,
  postGroup,
  patchGroup,
  deleteGroup,
} from "./groupsAction";

const initialState = {
  loading: false,
  error: null,
  success: false,
  permissions: {},
  groupItems: [],
};

const groupSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    resetGroupError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPermissions.pending, (state) => {
        state.loading = true;
        state.permissions = {};
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.loading = false;
        state.permissions = action.payload;
      })
      .addCase(getPermissions.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getGroups.pending, (state) => {
        state.loading = true;
        state.groupItems = [];
      })
      .addCase(getGroups.fulfilled, (state, action) => {
        state.loading = false;
        state.groupItems = action.payload;
      })
      .addCase(getGroups.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(postGroup.pending, (state) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(postGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.groupItems = [action?.payload, ...state?.groupItems];
        state.success = true;
      })
      .addCase(postGroup.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.success = false;
      })
      .addCase(patchGroup.pending, (state) => {
        state.loading = true;
      })
      .addCase(patchGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.groupItems = state?.groupItems?.map((el) => {
          if (el?.id === action?.payload?.id) {
            return action.payload;
          } else {
            return el;
          }
        });
        state.success = true;
      })
      .addCase(patchGroup.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.success = false;
      })
      .addCase(deleteGroup.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
        state.loading = false;
        state.groupItems = state?.groupItems?.filter(
          (el) => el?.id !== action?.payload?.id
        );
      })
      .addCase(deleteGroup.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});
export const { resetGroupError } = groupSlice.actions;
export default groupSlice.reducer;

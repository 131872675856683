import { createSlice } from "@reduxjs/toolkit";
import {
  getDriversBags,
  deleteBag,
  postDriverBag,
  getDriverBag,
  patchDriverBag,
} from "./driverBagsActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  bags: {},
  unassignedBags: {},
  bag: {},
};

const driversBagsSlice = createSlice({
  name: "driversBags",
  initialState,
  reducers: {
    resetBagsError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
    resetBags:(state)=>{
     state.bags={}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDriversBags.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDriversBags.fulfilled, (state, action) => {
        if (action.payload?.unassigned) {
          state.loading = false;
          state.unassignedBags = action.payload.data;
        } else {
          state.loading = false;
          state.bags = action.payload;
        }
      })
      .addCase(getDriversBags.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(deleteBag.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBag.fulfilled, (state, action) => {
        state.loading = false;
        state.bags = {
          meta: state.bags.meta,
          results: state.bags.results.filter(
            (el) => el.id !== action.payload.id
          ),
        };
      })
      .addCase(deleteBag.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(postDriverBag.pending, (state) => {
        state.loading = true;
      })
      .addCase(postDriverBag.fulfilled, (state, action) => {
        state.bags = {
          meta: state?.bags?.meta,
          results: [action.payload, ...state.bags.results],
        };
        state.loading = false;
        state.success = true;
      })
      .addCase(postDriverBag.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getDriverBag.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDriverBag.fulfilled, (state, action) => {
        state.loading = false;
        state.bag = action.payload;
      })
      .addCase(getDriverBag.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(patchDriverBag.pending, (state) => {
        state.loading = true;
      })
      .addCase(patchDriverBag.fulfilled, (state, action) => {
        state.bag = action.payload;
        state.loading = false;
        state.success = true;
      })
      .addCase(patchDriverBag.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});
export const { resetBagsError,resetBags } = driversBagsSlice.actions;
export default driversBagsSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { getBlogs, getBlog } from "./blogActions";

const initialState = {
    loading: false,
    error: null,
    success: false,
    blogsData: {},
    blog: {}
}


const blogsSlice = createSlice({
    name: "blogs",
    initialState,
    reducers: {
        resetBlogsError: (state, action) => {
            if (action?.payload) {
                delete state.error[action?.payload];
                if (Object.keys(state.error)?.length === 0) {
                    state.error = null;
                }
            } else {
                state.error = null;
            }
            state.loading = false;
            state.success = false;
        },
    },
    extraReducers: {
        [getBlogs.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [getBlogs.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.blogsData = {
                meta: payload?.meta,
                results: [...state?.blogsData?.results || [], ...payload?.results]
            };
            state.error = null;
        },
        [getBlogs.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        [getBlog.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [getBlog.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.blog = payload
            state.error = null;
        },
        [getBlog.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
    },
});
export const { resetBlogsError } = blogsSlice.actions;
export default blogsSlice.reducer;
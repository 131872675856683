import { theme } from "antd";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function useEditMenuModal() {
  const [inpVal, setInpVal] = useState("");
  const formRef = useRef(null);
  const { error, loading } = useSelector((state) => state?.menu);
  const dispatch = useDispatch();
  const { useToken } = theme;
  const {
    token: { Modal: modal, Button: button },
  } = useToken();

  const oninpChnage = (e) => {
    setInpVal(e.target.value);
  };

  return {
    modal,
    button,
    inpVal,
    oninpChnage,
    setInpVal,
    formRef,
    dispatch,
    loading,
    error
  };
}

import { createSlice } from "@reduxjs/toolkit";
import {
  getPatients,
  postPatient,
  deletePatient,
  updatePatient,
  getPatientsCities,
  getPatientShippingAddresses,
  postPatientShippingAddress,
  patchPatientShippingAddress,
} from "./patientActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  items: [],
  patientsCities: [],
  shipping_addresses: {},
};

const patientSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {
    resetError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: {
    // login user
    [getPatients.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPatients.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.items = payload;
      state.error = null;
    },
    [getPatients.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [postPatient.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [postPatient.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.items = {
        meta: state?.items?.meta,
        results: [payload, ...state?.items?.results],
      };
      state.error = null;
      state.success = true;
    },
    [postPatient.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [updatePatient.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updatePatient.fulfilled]: (state, { payload }) => {
      const { id } = payload;
      const updatedPatients = state.items?.results?.map((el) => {
        if (el?.id === id) {
          return payload;
        } else {
          return el;
        }
      });
      const newObj = {
        meta: state.items?.meta,
        results: updatedPatients,
      };
      state.items = newObj;
      state.loading = false;
      state.error = false;
      state.success = true;
    },
    [updatePatient.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [deletePatient.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deletePatient.fulfilled]: (state, { payload }) => {
      const { id } = payload;
      const updatedPatients = state.items?.results.filter((el) => el.id !== id);
      const newObj = {
        meta: state.items?.meta,
        results: updatedPatients,
      };
      state.items = newObj;
      state.loading = false;
      state.error = false;
    },
    [deletePatient.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getPatientsCities.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPatientsCities.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.patientsCities = payload;
      state.error = null;
    },
    [getPatientsCities.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getPatientShippingAddresses.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPatientShippingAddresses.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.shipping_addresses = payload;
      state.error = null;
    },
    [getPatientShippingAddresses.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [postPatientShippingAddress.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [postPatientShippingAddress.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.shipping_addresses = {
        meta: state.shipping_addresses?.meta,
        results: [payload, ...state?.shipping_addresses?.results],
      };
      state.error = null;
    },
    [postPatientShippingAddress.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [patchPatientShippingAddress.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [patchPatientShippingAddress.fulfilled]: (state, { payload }) => {
      const updatedAddresses = state?.shipping_addresses?.results?.map((el) => {
        if (el?.id === payload?.id) {
          return payload;
        } else {
          return el;
        }
      });
      const newObj = {
        meta: state?.shipping_addresses?.meta,
        results: updatedAddresses,
      };
      state.shipping_addresses = newObj;
      state.loading = false;
      state.error = false;
      state.success = true;
    },
    [patchPatientShippingAddress.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
export const { resetError } = patientSlice.actions;
export default patientSlice.reducer;

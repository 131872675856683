import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import axiosClient from "../../common/api/request";

export const getSafes = createAsyncThunk(
  "get_safes",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/safes/`,
        { params },
        config
      );
      return data;
    } catch (error) {
      if (
        error?.response &&
        error.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

export const getSafeItems = createAsyncThunk(
  "get_safe_items",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/safes/${params?.safeId}/menu_items`,
        { params },
        config
      );
      return data;
    } catch (error) {
      if (
        error?.response &&
        error.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

export const moveSafeItems = createAsyncThunk(
  "move_safe_items",
  async (params, { rejectWithValue }) => {
    const { safeId, ...restParams } = params;
    try {
      const data = await axiosClient.patch(
        `${config.API_URL}/safes/${safeId}/move_items`,
        { ...restParams },
        config
      );
      return data;
    } catch (error) {
      if (
        error?.response &&
        error.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

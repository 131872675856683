import React from "react";
import "./MetrcItemModal.scss";
import { Modal, Form, Typography, Select, Button } from "antd";
import useMetrcItemModal from "../../hooks/useMetrcItemModal";

export default function MetrcItemModal({ isOpened, title, onOk, onCancel }) {
  const {
    token,
    loading,
    error,
    formRef,
    dispatch,
    resetMetrcItemError,
    formData,
  } = useMetrcItemModal();
  return (
    <Modal
      className="metrcItemModal-cont"
      style={{
        borderTop: `2px solid ${token?.Button?.colorPrimary}`,
      }}
      open={isOpened}
      title={title}
      centered={true}
      okText="Save"
      closeIcon={false}
      cancelButtonProps={{
        className: "metrcItemModal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        className: "metrcItemModal-cont__btn",
        htmlType: "submit",
        type: "primary",
        key: "submit",
        loading: loading && isOpened,
        disabled: error ? Object.keys(error)?.length !== 0 : false,
      }}
      onCancel={() => {
        onCancel();
        formRef?.current?.resetFields();
        if (error) {
          dispatch(resetMetrcItemError());
        }
      }}
      onOk={() => {
        formRef?.current?.submit();
      }}
      footer={(_, { OkBtn, CancelBtn }) => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}>
          <CancelBtn />
          <Button size="small">Add new item</Button>
          <OkBtn />
        </div>
      )}>
      <Form
        className="metrcItemModal-cont__form"
        ref={formRef}
        layout="vertical"
        onFinish={(values) => {
          console.log("onFinsish", values);
        }}
        onFinishFailed={(error) => {
          console.log("onFinishFailed", error);
        }}
        fields={formData}>
        <div className="metrcItemModal-cont__form__formItemWrapper">
          <Form.Item
            className="metrcItemModal-cont__form__formItemWrapper__formItem"
            name="product"
            rules={[
              {
                required: true,
                message: "Please select product",
              },
            ]}
            label={
              <Typography.Text className="metrcItemModal-cont__form__formItemWrapper__formItem__text">
                Product
              </Typography.Text>
            }
            colon={false}>
            <Select
              placeholder="Product Name"
              // onChange={() => {
              //   if (error && error["product"]) {
              //     dispatch(resetMetrcItemError("product"));
              //   }
              // }}
            />
          </Form.Item>
        </div>
        <div className="metrcItemModal-cont__form__formItemWrapper">
          <Form.Item
            className="metrcItemModal-cont__form__formItemWrapper__formItem"
            name="category"
            rules={[
              {
                required: true,
                message: "Please select category",
              },
            ]}
            label={
              <Typography.Text className="metrcItemModal-cont__form__formItemWrapper__formItem__text">
                METRC category
              </Typography.Text>
            }
            colon={false}>
            <Select
              placeholder="Metrc Category"
              // onChange={() => {
              //   if (error && error["category"]) {
              //     dispatch(resetMetrcItemError("category"));
              //   }
              // }}
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}

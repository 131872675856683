import { createSlice } from "@reduxjs/toolkit";
import { getTopSoldCategories } from "./topSoldCategoriesAction";

const initialState = {
  loading: false,
  error: null,
  success: false,
  categories: [],
};

const topSoldCategorySlice = createSlice({
  name: "topSoldCatgories",
  initialState,
  reducers: {},
  extraReducers: {
    [getTopSoldCategories.pending]: (state) => {
      state.loading = true;
      state.categories = [];
      state.error = null;
    },
    [getTopSoldCategories.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.categories = payload;
      state.error = null;
    },
    [getTopSoldCategories.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
export default topSoldCategorySlice.reducer;

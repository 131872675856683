import { createSlice, current } from "@reduxjs/toolkit";
import {
  getItems,
  updateOrderDriver,
  updateOrderStatus,
  getOrderItems,
  updateOrderPaymentType,
  calculateOrder,
  postOrder,
  updateGrandTotal,
  patchOrder,
  patchOrderItems,
  patchOrderPromotionCode,
  getOrderRooms,
  getRoomOrders,
  postOrderRoom,
  patchOrderRoom,
  deleteOrderRoom,
  reportToMetric,
} from "./ordersActions";

const initialState = {
  loading: false,
  items: {},
  error: null,
  socketItems: [],
  success: false,
  orderItems: {},
  orderRooms: [],
  successUpdatedOrderItem: false,
  loading_state_betwCustomer_order: false,
  success_update_promotionCode: false,
  roomLoading: false,
  roomSuccess: false,
  roomError: null,
  isGettingRooms: true,
  socketData: [],
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    removeSocketOrders: (state, action) => {
      if (action?.payload?.id) {
        state.socketItems = state?.socketItems?.filter(
          (order) => order?.id !== action?.payload?.id
        );
      } else {
        state.socketItems = [];
      }
    },
    addSocketOrder: (state, action) => {
      state.socketItems.push(action.payload);
    },
    removeSocketData: (state, action) => {
      if (action?.payload?.id) {
        state.socketData = state?.socketData?.filter(
          (order) => order?.id !== action?.payload?.id
        );
      } else {
        state.socketData = [];
      }
    },
    addSocketData: (state, action) => {
      state.socketData.push(action.payload);
    },
    resetError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
      state.successUpdatedOrderItem = false;
      state.loading_state_betwCustomer_order = false;
      state.success_update_promotionCode = false;
    },
    constrolLoadingState_betweenCustomer_order: (state, action) => {
      state.loading_state_betwCustomer_order = action.payload.isLoading;
    },
    resetOrderRoomError: (state, action) => {
      if (action?.payload) {
        delete state.roomError[action?.payload];
        if (Object.keys(state.roomError)?.length === 0) {
          state.roomError = null;
        }
      } else {
        state.roomError = null;
      }
      state.roomLoading = false;
      state.roomSuccess = false;
      state.isGettingRooms = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getItems.fulfilled, (state, action) => {
        state.items = action.payload;
        state.loading = false;
        state.loading_state_betwCustomer_order = false;
      })
      .addCase(getItems.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(updateOrderDriver.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOrderDriver.fulfilled, (state, action) => {
        const updatedItems = state?.items?.results?.map((el) => {
          if (el?.id === action?.payload?.id) {
            return {
              ...el,
              driver: action.payload.driver,
            };
          } else {
            return el;
          }
        });
        state.items = {
          meta: state?.items?.meta,
          results: updatedItems,
        };
        state.loading = false;
      })
      .addCase(updateOrderDriver.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(updateOrderStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOrderStatus.fulfilled, (state, action) => {
        const updatedItems = state?.items?.results?.map((el) => {
          if (el?.id === action?.payload?.id) {
            return {
              ...el,
              status: action.payload.status,
            };
          } else {
            return el;
          }
        });
        state.items = {
          meta: state?.items?.meta,
          results: updatedItems,
        };
        state.loading = false;
      })
      .addCase(updateOrderStatus.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getOrderItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrderItems.fulfilled, (state, action) => {
        state.orderItems = action.payload;
        state.loading = false;
      })
      .addCase(getOrderItems.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(updateOrderPaymentType.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOrderPaymentType.fulfilled, (state, action) => {
        const updatedItems = state?.items?.results?.map((el) => {
          if (el?.id === action?.payload?.id) {
            return {
              ...el,
              payment_type: action.payload.payment_type,
            };
          } else {
            return el;
          }
        });
        state.items = {
          meta: state?.items?.meta,
          results: updatedItems,
        };
        state.loading = false;
      })
      .addCase(updateOrderPaymentType.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(calculateOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(calculateOrder.fulfilled, (state, action) => {
        const { id, ...params } = action.payload;
        const updatedItems = state?.items?.results?.map((el) => {
          if (el?.id === id) {
            return {
              ...el,
              ...params,
            };
          } else {
            return el;
          }
        });
        state.items = {
          meta: state?.items?.meta,
          results: updatedItems,
        };
        state.loading = false;
      })
      .addCase(calculateOrder.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(postOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(postOrder.fulfilled, (state, action) => {
        const results = state.items?.results || [];
        const newObj = {
          meta: state.items?.meta,
          results: [...results, action?.payload],
        };
        state.items = newObj;
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      .addCase(postOrder.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(updateGrandTotal.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateGrandTotal.fulfilled, (state, action) => {
        const updatedItems = state?.items?.results?.map((el) => {
          if (el?.id === action?.payload?.id) {
            return {
              ...el,
              grand_total: action.payload.grand_total,
            };
          } else {
            return el;
          }
        });
        state.items = {
          meta: state?.items?.meta,
          results: updatedItems,
        };
        state.loading = false;
      })
      .addCase(updateGrandTotal.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(patchOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(patchOrder.fulfilled, (state, action) => {
        const updatedItems = state?.items?.results?.map((el) => {
          if (el?.id === action?.payload?.id) {
            return {
              ...el,
              address: action.payload.address,
            };
          } else {
            return el;
          }
        });
        state.items = {
          meta: state?.items?.meta,
          results: updatedItems,
        };
        state.loading = false;
        state.success = true;
        state.loading_state_betwCustomer_order = false;
      })
      .addCase(patchOrder.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.success = false;
      })
      .addCase(patchOrderItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(patchOrderItems.fulfilled, (state, action) => {
        const updatedItems = state?.items?.results?.map((el) => {
          if (el?.id === action?.payload?.id) {
            return {
              ...el,
              order_items: action?.payload?.order_items,
              tax_total: action?.payload?.tax_total,
              discount_total: action?.payload?.discount_total,
              sales_tax: action?.payload?.sales_tax,
              excise_tax: action?.payload?.excise_tax,
              grand_total: action?.payload?.grand_total,
            };
          } else {
            return el;
          }
        });
        state.items = {
          meta: state?.items?.meta,
          results: updatedItems,
        };
        state.loading = false;
        state.successUpdatedOrderItem = true;
      })
      .addCase(patchOrderItems.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.success = false;
        state.successUpdatedOrderItem = false;
      })
      .addCase(patchOrderPromotionCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(patchOrderPromotionCode.fulfilled, (state, action) => {
        const updatedItems = state?.items?.results?.map((el) => {
          if (el?.id === action?.payload?.id) {
            return {
              ...el,
              discount: action?.payload?.discount,
              tax_total: action?.payload?.tax_total,
              discount_total: action?.payload?.discount_total,
              sales_tax: action?.payload?.sales_tax,
              excise_tax: action?.payload?.excise_tax,
              grand_total: action?.payload?.grand_total,
            };
          } else {
            return el;
          }
        });
        state.items = {
          meta: state?.items?.meta,
          results: updatedItems,
        };
        state.loading = false;
        state.success_update_promotionCode = true;
      })
      .addCase(patchOrderPromotionCode.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.success = false;
        state.success_update_promotionCode = false;
      })
      .addCase(getOrderRooms.pending, (state) => {
        state.roomLoading = true;
        state.isGettingRooms = true;
      })
      .addCase(getOrderRooms.fulfilled, (state, action) => {
        state.orderRooms = action.payload;
        state.roomLoading = false;
        state.isGettingRooms = false;
      })
      .addCase(getOrderRooms.rejected, (state, action) => {
        state.roomError = action.payload;
        state.roomLoading = false;
        state.isGettingRooms = false;
      })
      .addCase(reportToMetric.pending, (state) => {
        state.roomLoading = true;
      })
      .addCase(reportToMetric.fulfilled, (state, action) => {
        state.roomLoading = false;
      })
      .addCase(reportToMetric.rejected, (state, action) => {
        state.roomLoading = false;
      })
      .addCase(getRoomOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRoomOrders.fulfilled, (state, action) => {
        state.items = action.payload;
        state.loading = false;
        state.loading_state_betwCustomer_order = false;
      })
      .addCase(getRoomOrders.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(postOrderRoom.pending, (state) => {
        state.roomLoading = true;
      })
      .addCase(postOrderRoom.fulfilled, (state, action) => {
        const rooms = state.orderRooms || [];
        state.orderRooms = [...rooms, action?.payload];
        state.roomLoading = false;
        state.roomSuccess = true;
      })
      .addCase(postOrderRoom.rejected, (state, action) => {
        state.roomError = action.payload;
        state.roomLoading = false;
        state.roomSuccess = false;
      })
      .addCase(patchOrderRoom.pending, (state) => {
        state.roomLoading = true;
      })
      .addCase(patchOrderRoom.fulfilled, (state, action) => {
        const rooms = state.orderRooms || [];
        const newRooms = rooms?.map((el) => {
          if (el?.id === action?.payload?.id) {
            return action?.payload;
          } else {
            return el;
          }
        });
        state.orderRooms = newRooms;
        state.roomLoading = false;
        state.roomSuccess = true;
      })
      .addCase(patchOrderRoom.rejected, (state, action) => {
        state.roomError = action.payload;
        state.roomLoading = false;
        state.roomSuccess = false;
      })
      .addCase(deleteOrderRoom.pending, (state) => {
        state.roomLoading = true;
      })
      .addCase(deleteOrderRoom.fulfilled, (state, action) => {
        state.orderRooms = state?.orderRooms?.filter(
          (el) => el?.id !== action?.payload?.id
        );
        state.roomLoading = false;
      })
      .addCase(deleteOrderRoom.rejected, (state, action) => {
        state.roomError = action.payload;
        state.roomLoading = false;
      });
  },
});
export const {
  resetError,
  constrolLoadingState_betweenCustomer_order,
  addSocketOrder,
  removeSocketOrders,
  resetOrderRoomError,
  addSocketData,
  removeSocketData,
} = ordersSlice.actions;
export default ordersSlice.reducer;

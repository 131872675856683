import { createAsyncThunk } from "@reduxjs/toolkit"
import config from "../../config"
import axiosClient from "../../common/api/request"

export const getOrganizations = createAsyncThunk(
  'organizations',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosClient.get(
        `${config.API_URL}/organizations/`,
        {  },
        config
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

import "./ConfirmModal.scss";
import { Modal, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import useConfirmMenuModal from "../../hooks/useConfirmMenuModal";
export default function ConfirmModal({
  title,
  isOpened,
  subTitle,
  onOk,
  onCancel,
  icon,
  afterResponse
}) {
  const { modal, button, text, token } = useConfirmMenuModal();
  return (
    <Modal
      centered
      closeIcon={false}
      className="confirmModal-cont"
      onOk={() => {
        onOk();
        if (!afterResponse) {
          onCancel();
        }
      }}
      okText={"Yes"}
      onCancel={onCancel}
      title={
        <div className="confirmModal-cont__titleWrapper">
          {icon ? (
            icon
          ) : (
            <DeleteOutlined
              style={{
                color: `${token?.colorErrorText}`,
              }}
              className="confirmModal-cont__titleWrapper__icon"
            />
          )}
          <Typography.Text className="confirmModal-cont__titleWrapper__title">
            {title}
          </Typography.Text>
        </div>
      }
      cancelText="No"
      cancelButtonProps={{
        size: "small",
        className: "confirmModal-cont__btn",
      }}
      okButtonProps={{
        size: "small",
        type: "primary",
        className: "confirmModal-cont__btn",
      }}
      open={isOpened}
      style={{
        borderTop: `2px solid ${button?.colorPrimary}`,
      }}
    >
      <Typography.Text
        className="confirmModal-cont__subTitle"
        style={{
          color: `${text.titleColor}`,
        }}
      >
        {subTitle}
      </Typography.Text>
    </Modal>
  );
}

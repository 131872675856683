import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import LocalStorageService from "../../common/services/LocalStorageService";
import axiosClient from "../../common/api/request";

export const forgetPassword = createAsyncThunk(
  "forgetPassword",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/password_reset/`,
        params,
        config
      );
      return data;
    } catch (error) {
      if (error?.response && error?.response?.data) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

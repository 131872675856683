import { createSlice } from "@reduxjs/toolkit";
import { getMetrcTransfers, metrcTransfersSync } from "./metrcTransfersActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  transfers: {},
};

const metrcTransfersSlice = createSlice({
  name: "metrcTransfers",
  initialState,
  reducers: {
    resetMetrcTransactionError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: {
    [getMetrcTransfers.pending]: (state) => {
      state.loading = true;
    },
    [getMetrcTransfers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.transfers = payload;
    },
    [getMetrcTransfers.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [metrcTransfersSync.pending]: (state) => {
      state.loading = true;
    },
    [metrcTransfersSync.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [metrcTransfersSync.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { resetMetrcTransactionError } = metrcTransfersSlice.actions;
export default metrcTransfersSlice.reducer;
import { useEffect, useRef, useState } from "react";
import { theme } from "antd";
import useWindowWidth from "./useWindowWidth";
import { resetError } from "../features/metrics/metricSlice";
import { useDispatch, useSelector } from "react-redux";

export default function useMetrcInventoryModal(api, data) {
  const { useToken } = theme;
  const { token } = useToken();
  const dispatch = useDispatch();
  const { width } = useWindowWidth();
  const formRef = useRef(null);
  const autoFillFormRef = useRef(null);
  const [formData, setFormData] = useState([]);
  const [isAutoFillModalOPened, setIsAutofillModalOpened] = useState(false);
  const {
    error,
    loading
  } = useSelector((state) => state.metrics);
  const openNotificationWithIcon = (type, title, text) => {
    api[type]({
      message: title,
      description: text,
      placement: "top",
    });
  };
  useEffect(() => {
    if (error && !loading) {
      for (let key in error[0]) {
        openNotificationWithIcon("error", key, error[0][key][0]);
      }
      dispatch(resetError());
    }
}, [error, loading]);
  const tableColumns = [ 
    {
      title: "Unique #",
      dataIndex: "unique",
      key: "unique",
      render: (_, record) => {
        return record?.id
      }
    },
    {
      title: "Expected",
      dataIndex: "expected",
      key: "expected",
    },
    {
      title: "Difference",
      dataIndex: "difference",
      key: "difference",
      render: (_, record) => {
        return record?.difference;
      },
    },
  ];

  const onAutoFillSave = (val) => {
    formRef?.current?.resetFields();
    let form_data = data?.map((el) => {
      if (el?.id) {
        return [
          {
            name: `${el?.id} reason`,
            value: val?.reason ? val?.reason : "",
          },
          {
            name: `${el?.id} metrc_reason`,
            value: val?.metrc_reason ? val?.metrc_reason : "",
          },
          {
            name: `${el?.id} note`,
            value: val?.note ? val?.note : "",
          },
        ];
      }
    });

    setFormData(form_data?.flat());
  };

  return {
    token,
    width,
    formRef,
    formData,
    tableColumns,
    isAutoFillModalOPened,
    setIsAutofillModalOpened,
    autoFillFormRef,
    onAutoFillSave,
    dispatch
  };
}

import React from "react";
import "./TransferMenuItemModal.scss";
import { Modal, Form, Typography, Select } from "antd";
import useTransferMenuItemModal from "../../hooks/useTransferMenuItemModal";

export default function TransferMenuItemModal({
  isOpened,
  title,
  onOk,
  onCancel,
  currentMenuId,
  currentSubMenuId,
  callWhenItemsSynced,
  setSelectedRowKeys
}) {
  const {
    formRef,
    token,
    loading,
    error,
    dispatch,
    resetErrors,
    selectOptions,
    filterOption,
  } = useTransferMenuItemModal(
    currentMenuId,
    currentSubMenuId,
    isOpened,
    onCancel,
    callWhenItemsSynced,
    setSelectedRowKeys
  );

  return (
    <Modal
      className="transferMenuItemModal-cont"
      open={isOpened}
      title={title}
      centered={true}
      okText="Save"
      closeIcon={false}
      onOk={() => {
        formRef.current.submit();
        if (error) {
          dispatch(resetErrors());
        }
      }}
      onCancel={() => {
        onCancel();
        formRef.current.resetFields();
        dispatch(resetErrors());
      }}
      style={{
        borderTop: `2px solid ${token?.Button?.colorPrimary}`,
      }}
      cancelButtonProps={{
        className: "transferMenuItemModal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        htmlType: "submit",
        type: "primary",
        className: "transferMenuItemModal-cont__btn",
        loading: loading,
        disabled: error ? Object.keys(error)?.length !== 0 : false,
      }}
    >
      <Form
        className="transferMenuItemModal-cont__form"
        layout="vertical"
        ref={formRef}
        onFinish={(values) => {
          console.log("onfinish", values);
          onOk(values);
        }}
        onFinishFailed={(error) => {
          console.log("onfinishFailed", error);
        }}
      >
        <Form.Item
          className="transferMenuItemModal-cont__form__formItem"
          name="sub_menus"
          rules={[
            {
              required: true,
              message: "Please select sub menu",
            },
          ]}
          colon={false}
          label={
            <Typography.Text
              className="transferMenuItemModal-cont__form__formItem__text"
              style={{
                color: `${token?.Modal.titleColor}`,
              }}
            >
              Sub menu
            </Typography.Text>
          }
        >
          <Select
            allowClear
            mode="multiple"
            type="text"
            placeholder="Sub menu"
            disabled={loading}
            showSearch
            onChange={() => {
              if (error) {
                dispatch(resetErrors());
              }
            }}
            filterOption={filterOption}
            options={selectOptions}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

import React from "react";
import { Modal, Form, Typography, Input } from "antd";
import "./ChangePasswordModal.scss";
import useChangePasswordModal from "../../hooks/useChangePasswordModal";
export default function ChangePasswordModal({
  isOpened,
  title,
  onOk,
  onCancel,
}) {
  const {
    token,
    formRef,
    resetPassword,
    loading,
    error,
    dispatch,
    resetChangePassWordError,
  } = useChangePasswordModal(isOpened, onCancel);
  return (
    <Modal
      className="changePasswordModal-cont"
      open={isOpened}
      title={title}
      centered={true}
      okText="Save"
      closeIcon={false}
      onOk={() => {
        if (error) {
          dispatch(resetChangePassWordError());
        }
        formRef.current.submit();
      }}
      onCancel={() => {
        if (error) {
          dispatch(resetChangePassWordError());
        }
        onCancel();
        formRef.current.resetFields();
      }}
      style={{
        borderTop: `2px solid ${token?.Button?.colorPrimary}`,
      }}
      cancelButtonProps={{
        className: "changePasswordModal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        className: "changePasswordModal-cont__btn",
        htmlType: "submit",
        type: "primary",
        key: "submit",
        loading: loading,
        disabled: error ? Object.keys(error)?.length !== 0 : false,
      }}
    >
      <Form
        className="changePasswordModal-cont__form"
        ref={formRef}
        layout="vertical"
        onFinish={(values) => {
          //   const modifiedForPhoneNumber = {
          //     ...values,
          //     phone_number: `${phoneCode}${values?.phone_number}`,
          //     // Add or modify fields as needed
          //   };
          resetPassword({
            old_password: values?.old_password,
            new_password: values?.new_password,
          });
        }}
        onFinishFailed={(error) => {
        }}
      >
        <Form.Item
          className="changePasswordModal-cont__form__formItem"
          name="old_password"
          rules={[
            {
              required: true,
              message: "Please enter your current Password",
            },
          ]}
          label={
            <Typography.Text
              className="changePasswordModal-cont__form__formItem__text"
              //   style={{
              //     fontFamily: `${token?.Typography?.fontFamily}`,
              //   }}
            >
              Current Password
            </Typography.Text>
          }
          colon={false}
        >
          <Input.Password
            type="text"
            placeholder="Current Password"
            onChange={() => {
              if (error && error["old_password"]) {
                dispatch(resetChangePassWordError("old_password"));
              }
            }}
          />
        </Form.Item>
        <Form.Item
          className="changePasswordModal-cont__form__formItem"
          name="new_password"
          rules={[
            {
              required: true,
              message: "Please enter your new password",
            },
          ]}
          hasFeedback
          label={
            <Typography.Text
              className="changePasswordModal-cont__form__formItem__text"
              //   style={{
              //     fontFamily: `${token?.Typography?.fontFamily}`,
              //   }}
            >
              New Password
            </Typography.Text>
          }
          colon={false}
        >
          <Input.Password type="text" placeholder="New Password" />
        </Form.Item>
        <Form.Item
          className="changePasswordModal-cont__form__formItem"
          name="repeat_new_password"
          dependencies={["new_password"]}
          rules={[
            {
              required: true,
              message: "Please repeat your new password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
          hasFeedback
          label={
            <Typography.Text
              className="changePasswordModal-cont__form__formItem__text"
              //   style={{
              //     fontFamily: `${token?.Typography?.fontFamily}`,
              //   }}
            >
              Repeat New Password
            </Typography.Text>
          }
          colon={false}
        >
          <Input.Password type="text" placeholder="Repeat New Password" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

import "./AddSaleModal.scss";
import { Typography, Modal, Input, Form } from "antd";
import useAddSaleModal from "../../hooks/useAddSaleModal";
import { useEffect } from "react";

export default function AddSaleModal({
  isOpened,
  title,
  subTitle,
  onOk,
  onCancel,
  sale,
}) {
  const { modal, button, formRef } = useAddSaleModal();

  useEffect(() => {
    // if (sale) {
    formRef?.current?.setFieldsValue({
      salenumber: sale,
    });
    // }
  }, [sale, isOpened]);

  const validateNumber = (rule, value) => {
    const numberValue = parseFloat(value);

    if (!value) {
      return Promise.resolve();
    } else if (isNaN(numberValue)) {
      return Promise.reject("Please enter a valid number");
    }

    if (numberValue > 100 || numberValue < 0) {
      return Promise.reject("Should be between 0 and 100");
    }

    return Promise.resolve();
  };

  return (
    <Modal
      open={isOpened}
      title={title}
      onOk={() => {
        formRef.current.submit();
      }}
      okText="Save"
      closeIcon={false}
      centered
      onCancel={() => {
        onCancel();
        formRef.current.setFieldValue("salenumber", "");
      }}
      style={{
        borderTop: `2px solid ${button?.colorPrimary}`,
      }}
      className="addsalemodal-cont"
      cancelButtonProps={{
        className: "addsalemodal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        htmlType: "submit",
        type: "primary",
        className: "addsalemodal-cont__btn",
      }}
    >
      <Form
        layout="vertical"
        ref={formRef}
        className="addsalemodal-cont__form"
        onFinish={(values) => {
          onOk(values["salenumber"]);
          formRef.current.setFieldValue("salenumber", "");
          onCancel();
        }}
        onFinishFailed={(error) => {
        }}
      >
        <Form.Item
          initialValue={sale}
          name="salenumber"
          className="addsalemodal-cont__form__formItem"
          rules={[
            {
              validator: validateNumber,
            },
          ]}
          colon={false}
          label={
            <Typography.Text
              className="addsalemodal-cont__form__formItem__text"
              style={{
                color: `${modal.titleColor}`,
              }}
            >
              {subTitle}
            </Typography.Text>
          }
        >
          <Input
            defaultValue={sale}
            type="text"
            prefix={<Typography.Text>%</Typography.Text>}
            onKeyDown={(event) => {
              const { key } = event;
              if (
                !/[0-9.]/.test(key) &&
                key !== "Backspace" &&
                key !== "ArrowLeft" &&
                key !== "ArrowRight"
              ) {
                event.preventDefault();
              }
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import {
  getIntegretionSetting,
  updateInterationSetting,
} from "./integrationSettingActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  integrations: {},
};

const integrationSettingSlice = createSlice({
  name: "integrationSetting",
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIntegretionSetting.pending, (state) => {
        state.loading = true;
      })
      .addCase(getIntegretionSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.integrations = action.payload;
      })
      .addCase(getIntegretionSetting.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(updateInterationSetting.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInterationSetting.fulfilled, (state, action) => {
        state.loading = false;
        const updatedSettings = state.integrations?.results?.map((el) => {
          if (el?.id === action?.payload?.id) {
            return action.payload;
          } else {
            return el;
          }
        });

        state.integrations = {
          ...state.integrations,
          results: updatedSettings,
        };
      })
      .addCase(updateInterationSetting.rejected, (state, action) => {
        state.error = action?.payload;
        state.loading = false;
      });
  },
});
export const { resetError } = integrationSettingSlice.actions;
export default integrationSettingSlice.reducer;

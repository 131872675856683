import React from "react";
import { Modal, Form, Typography, Input, Select } from "antd";
import "./DriverModal.scss";
import useDriverModal from "../../hooks/useDriverModal";
// const { Option } = Select;
export default function DriverModal({
  isOpened,
  title,
  onOk,
  onCancel,
  driver,
  bags,
  allBags,
}) {
  const {
    token,
    formRef,
    phoneCode,
    setPhoneCode,
    phoneCodeValidator,
    selectBefore,
    formData,
    emailValidator,
    handleFiledsChanged,
    isFieldsChanged,
    setIsFieldsChanged,
    setChangedFields,
    getSelectOptions,
    loading,
    dispatch,
    resetError,
    error,
  } = useDriverModal(driver, isOpened, allBags, onCancel);
  return (
    <Modal
      className="creationModal-cont"
      open={isOpened}
      title={title}
      centered={true}
      okText="Save"
      closeIcon={false}
      onOk={() => {
        formRef.current.submit();
        dispatch(resetError());
      }}
      onCancel={() => {
        onCancel();
        setPhoneCode("");
        formRef.current.resetFields();
        setIsFieldsChanged(false);
        setChangedFields({});
        dispatch(resetError());
      }}
      style={{
        borderTop: `2px solid ${token?.Button?.colorPrimary}`,
      }}
      cancelButtonProps={{
        className: "creationModal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        className: "creationModal-cont__btn",
        htmlType: "submit",
        type: "primary",
        key: "submit",
        loading: loading,
        disabled: error ? Object.keys(error)?.length !== 0 : false,
      }}>
      <Form
        ref={formRef}
        layout="vertical"
        className="creationModal-cont__form"
        onFieldsChange={(changedFields) => {
          handleFiledsChanged({
            name: changedFields[0]?.name[0],
            value: changedFields[0]?.value,
          });
        }}
        onFinish={(values) => {
          const modifiedvals = {
            ...values,
            phone_number: `${phoneCode}${values?.phone_number}`,
            // Add or modify fields as needed
          };
          if (driver) {
            if (isFieldsChanged) {
              // this case is for first case when nothing is chnaged
              if (typeof values?.bag === "string") {
                modifiedvals.bag = allBags?.find(
                  (el) => el?.name === values?.bag
                )?.id;
                onOk(modifiedvals);
              } else {
                if (modifiedvals?.bag === 0) {
                  modifiedvals.bag = null;
                }
                onOk(modifiedvals);
              }
            } else {
              onCancel();
              // setIsFieldsChanged(false)
            }
          } else {
            onOk(modifiedvals);
          }
        }}
        onFinishFailed={(error) => {}}
        fields={formData}>
        <div className="creationModal-cont__form__formItemWrapper">
          <Form.Item
            className="creationModal-cont__form__formItemWrapper__formItem"
            name="first_name"
            rules={[
              {
                required: true,
                message: "Please enter your First Name",
              },
            ]}
            label={
              <Typography.Text className="creationModal-cont__form__formItemWrapper__formItem__text">
                First Name
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="first Name"
              onChange={() => {
                if (error && error["first_name"]) {
                  dispatch(resetError("first_name"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="creationModal-cont__form__formItemWrapper__formItem"
            name="last_name"
            rules={[
              {
                required: true,
                message: "Please enter your Last Name",
              },
            ]}
            label={
              <Typography.Text className="creationModal-cont__form__formItemWrapper__formItem__text">
                Last Name
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="Last Name"
              onChange={() => {
                if (error && error["last_name"]) {
                  dispatch(resetError("last_name"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="creationModal-cont__form__formItemWrapper">
          <Form.Item
            name="phone_number"
            style={{
              marginTop: "5px",
            }}
            rules={[
              // {
              //   required: true,
              //   message: "Please enter your Phone Number",
              // },
              {
                validator: phoneCodeValidator,
              },
            ]}
            className="creationModal-cont__form__formItemWrapper__formItem"
            label={
              <Typography.Text className="creationModal-cont__form__formItemWrapper__formItem__text">
                Phone Number
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="number"
              addonBefore={selectBefore}
              placeholder="Phone Number"
              onChange={() => {
                if (error && error["phone_number"]) {
                  dispatch(resetError("phone_number"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="creationModal-cont__form__formItemWrapper__formItem"
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email",
              },
              // for custom validation
              {
                validator: emailValidator,
              },
            ]}
            label={
              <Typography.Text className="creationModal-cont__form__formItemWrapper__formItem__text">
                Email
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="Email"
              onChange={() => {
                if (error && error["email"]) {
                  dispatch(resetError("email"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="creationModal-cont__form__formItemWrapper">
          <Form.Item
            className="creationModal-cont__form__formItemWrapper__formItemSelect"
            name="telegram_token"
            label={
              <Typography.Text className="creationModal-cont__form__formItemWrapper__formItem__text">
                Telegram token
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="Telegram token"
              onChange={() => {
                if (error && error["telegram_token"]) {
                  dispatch(resetError("telegram_token"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="creationModal-cont__form__formItemWrapper">
          <Form.Item
            className="creationModal-cont__form__formItemWrapper__formItemSelect"
            name="bag"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter your bag",
            //   },
            // ]}
            label={
              <Typography.Text className="creationModal-cont__form__formItemWrapper__formItem__text">
                Bag
              </Typography.Text>
            }
            colon={false}>
            <Select
              className="creationModal-cont__form__formItemWrapper__formItemSelect__select"
              placeholder="Bag"
              options={getSelectOptions(driver?.bag, bags)}
              onChange={() => {
                if (error && error["bag"]) {
                  dispatch(resetError("bag"));
                }
              }}
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}

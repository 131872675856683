import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMe } from "../features/me/meActions";
import { useNavigate } from "react-router";
import LocalStorageService from "../common/services/LocalStorageService";


export default function useApp() {
  const me = useSelector((state)=>state?.me?.info)
  const dispatch = useDispatch()
  const user = useSelector((state)=>state?.auth?.user)
  const navigate = useNavigate()
  useEffect(()=>{
    if(LocalStorageService.get("authorizationData")?.access){
      dispatch(getMe())
    }
  },[user])
  // useEffect(()=>{
  //  navigate("/admin")
  // },[me])
  return {
    me
  };
}

import { theme } from "antd";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetMetrcItemError } from "../features/metrics/MetrcItems/metrcItemsSlice";

export default function useMetrcItemModal() {
  const { useToken } = theme;
  const { token } = useToken();
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state?.metrcItems);
  const formRef = useRef(null);
  const [formData, setFormData] = useState([]);
  
  return {
    token,
    loading,
    error,
    formRef,
    dispatch,
    resetMetrcItemError,
    formData,
  };
}

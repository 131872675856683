import { theme, message } from "antd";
import { useRef, useState } from "react";
import axiosClient from "../common/api/request";
import config from "../config";

export default function useMetrcTagModal(onCancel) {
  const { useToken } = theme;
  const { token } = useToken();
  const [messageApi, contextHolder] = message.useMessage();
  const openMessage = (type, text) => {
    messageApi.open({
      type: type,
      content: text,
    });
  };
  const formRef = useRef();
  const [formData, setFormData] = useState([]);
  const [file, setFile] = useState(null);
  const [fileProgress, setFileProgress] = useState(0);
  const [fileUploadError, setFileUploadError] = useState(false);

  const postFile = async (formData) => {
    const { onSuccess, onError, file, onProgress } = formData;
    const reader = new FileReader();
    if (file) {
      reader.readAsText(file);
    }
    const newFormData = new FormData();
    newFormData.append("file", file);
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/metrcs/tags/import/`,
        newFormData,
        {
          config,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (event) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setFileProgress(percent);
            if (percent === 100) {
              setTimeout(() => setFileProgress(0), 1000);
            }
            onProgress({ percent: (event.loaded / event.total) * 100 });
          },
        }
      );
      setFile({
        status: "success",
        id: data?.id,
        url: data?.image,
        uid: file?.uid,
        name: file?.name,
      });
      onSuccess("Ok");
      openMessage("success", "FIle successfully uploaded");
      setTimeout(() => {
        onCancel();
        setFile(null);
        setFileProgress(0);
        setFileUploadError(false);
        formRef?.current?.resetFields();
      }, 1500);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        const errorKeys = Object.keys(error.response.data);
        const firstErrorKey = errorKeys?.length > 0 ? errorKeys[0] : null;
        const errorMessage = firstErrorKey
          ? error.response.data[firstErrorKey][0]
          : "An error occurred.";

        // formRef?.current?.setFields([
        //   {
        //     name: "Files",
        //     errors: [errorMessage],
        //   },
        // ]);
        openMessage("error", errorMessage);
        onError({ errorMessage });
      } else {
        onError({ message });
        // formRef?.current?.setFields([
        //   {
        //     name: "Files",
        //     errors: ["File upload failed"],
        //   },
        // ]);
        openMessage("error", "File upload failed");
      }
      setFileUploadError(true);
      setTimeout(() => setFileUploadError(false), 1000);
    }
  };

  const onFileRemove = () => {
    setFile(null);
    formRef?.current?.resetFields();
  };

  const props = {
    name: "file",
    multiple: true,
    customRequest: postFile,
    maxCount: 1,
    accept: ".csv",
    fileList: file ? [file] : [],
    onRemove: onFileRemove,
    onChange(info) {
      if (info.file.status !== "removed") {
        setFile({
          uid: info?.file?.originFileObj?.uid,
          name: info?.file?.originFileObj?.name,
          status: info?.file?.status,
        });
      } else {
        setFile(null);
        formRef?.current?.resetFields();
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return {
    token,
    formRef,
    setFormData,
    formData,
    props,
    fileProgress,
    fileUploadError,
    setFile,
    file,
    setFileUploadError,
    setFileProgress,
    contextHolder,
  };
}

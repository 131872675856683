import { createSlice } from "@reduxjs/toolkit";
import { getRoles, postRole, deleteRole, patchRole } from "./rolesAction";

const initialState = {
  loading: false,
  error: null,
  success: false,
  items: {},
};

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    resetError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.pending, (state) => {
        state.loading = true;
        state.items = {};
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(postRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(postRole.fulfilled, (state, action) => {
        state.loading = false;
        state.items = {
          meta: state.items.meta,
          results: [...state.items.results, action.payload],
        };
        state.success = true;
      })
      .addCase(postRole.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.success = false;
      })
      .addCase(deleteRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.loading = false;
        state.items = {
          meta: state.items.meta,
          results: state.items.results.filter(
            (el) => el.id !== action.payload.id
          ),
        };
      })
      .addCase(deleteRole.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(patchRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(patchRole.fulfilled, (state, action) => {
        state.loading = false;
        state.items = {
          meta: state.items.meta,
          results: state.items.results.map((el) =>
            el.id === action.payload.id ? action.payload : el
          ),
        };
        state.success = true;
      })
      .addCase(patchRole.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.success = false;
      });
  },
});
export const { resetError } = rolesSlice.actions;
export default rolesSlice.reducer;

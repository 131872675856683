import React from "react";
import "./AutoFillModal.scss";
import { theme, Modal, Form, Typography, Select, Input } from "antd";

export default function AutoFillModal({
  isOpened,
  title,
  onOk,
  onCancel,
  formRef,
  reasons
}) {
  const { useToken } = theme;
  const { token } = useToken();

  return (
    <Modal
      className="autoFillModal-cont"
      style={{
        borderTop: `2px solid ${token?.Button?.colorPrimary}`,
      }}
      open={isOpened}
      title={title}
      centered={true}
      okText="Save"
      closeIcon={false}
      cancelButtonProps={{
        className: "autoFillModal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        className: "autoFillModal-cont__btn",
        htmlType: "submit",
        type: "primary",
        key: "submit",
        // loading: loading && isOpened,
        // disabled: error ? Object.keys(error)?.length !== 0 : false,
      }}
      onCancel={() => {
        onCancel();
        formRef?.current?.resetFields();
        // if (error) {
        //   dispatch(resetMetrcInventoryError());
        // }
      }}
      onOk={() => {
        formRef?.current?.submit();
      }}>
      <Form
        className="autoFillModal-cont__form"
        ref={formRef}
        layout="vertical"
        onFinish={(values) => {
         onOk(values)
         onCancel()
         formRef?.current?.resetFields();
        }}
        onFinishFailed={(error) => {
          console.log("onFinishFailed", error);
        }}>
        <Form.Item
          className="autoFillModal-cont__form__formItem"
          name="reason"
          rules={[
            {
              required: true,
              message: "Please select reason",
            },
          ]}
          label={
            <Typography.Text className="autoFillModal-cont__form__formItem__text">
              Reason
            </Typography.Text>
          }
          colon={false}>
          <Select
            placeholder="Reason"
            options={reasons.map(reason => {
              return {
                label: reason,
                value: reason,
              }
            })}
          />
        </Form.Item>
        {/* <Form.Item
          className="autoFillModal-cont__form__formItem"
          name="metrc_reason"
          rules={[
            {
              required: true,
              message: "Please select metrc reason",
            },
          ]}
          label={
            <Typography.Text className="autoFillModal-cont__form__formItem__text">
              Metrc Reason
            </Typography.Text>
          }
          colon={false}>
          <Select
            placeholder="Metrc Reason"
            options={[
              {
                label: "Metrc reason",
                value: "metrc_reason",
              },
            ]}
          />
        </Form.Item> */}
        <Form.Item
          className="autoFillModal-cont__form__formItem"
          name="note"
          rules={[
            {
              required: true,
              message: "Please input note",
            },
          ]}
          label={
            <Typography.Text className="autoFillModal-cont__form__formItem__text">
              Note
            </Typography.Text>
          }
          colon={false}>
          <Input placeholder="Note" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

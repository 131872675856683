import { createSlice } from "@reduxjs/toolkit";
import { userLogin } from "./authActions";
import LocalStorageService from "../../common/services/LocalStorageService";

// initialize userToken from local storage
const userToken = localStorage.getItem("userToken")
  ? localStorage.getItem("userToken")
  : null;

const initialState = {
  loading: false,
  user: null,
  userToken,
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
      state.loading = false;
    },
    logOut: (state) => {
      state.success = false;
      state.error = null;
      state.userToken = null;
      state.user = null;
      state.loading = false;
      LocalStorageService.remove("authorizationData");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.userToken = action.payload.access;
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.error = action.payload;
        state.isAuthenticated = false;
        state.loading = false;
      });
  },
});
export const { resetError, logOut } = authSlice.actions;
export default authSlice.reducer;

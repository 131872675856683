import { createSlice } from "@reduxjs/toolkit";
import { forgetPassword } from "./forgetPasswordAction";


const initialState = {
  message:null,
  success: false,
  loading:false,
  error:null
};

const forgetPasswordSlice = createSlice({
  name: "forgetPassword",
  initialState,
  reducers: {
    resetForgetPassWordError: (state) => {
      state.error = null;
      state.loading = false;
      state.success = false;
      state.message = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgetPassword.fulfilled, (state, action) => {
        state.message = action?.payload
        state.loading = false;
        state.success = true
      })
      .addCase(forgetPassword.rejected, (state, action) => {
        state.error = action?.payload;
        state.loading = false;
        state.success = false
      });
  },
});
export const { resetForgetPassWordError} = forgetPasswordSlice?.actions;
export default forgetPasswordSlice.reducer;
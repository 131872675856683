import { theme } from "antd";
import { useRef, useState } from "react";

export default function useAddSaleModal() {
  const [inpVal, setInpVal] = useState("");
  const formRef = useRef(null);
  const oninpChnage = (e) => {
    setInpVal(e.target.value);
  };
  const { useToken } = theme;
  const {
    token: { Modal: modal, Button: button },
  } = useToken();
  return {
    modal,
    button,
    inpVal,
    oninpChnage,
    setInpVal,
    formRef,
  };
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import axiosClient from "../../common/api/request";
import LocalStorageService from "../../common/services/LocalStorageService";

export const getMe = createAsyncThunk(
  "Me",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.get(
        `${config.API_URL}/me/`,
        { params },
        config
      );
      const tokens = LocalStorageService.get("authorizationData");
      LocalStorageService.set("authorizationData", {
        access:tokens.access,
        refresh:tokens.refresh,
        is_superuser: data.is_superuser,
        group_permissions: data.group_permissions,
        driver: data.driver
      });
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);
export const updateMe = createAsyncThunk(
  "updateMe",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.patch(
        `${config.API_URL}/account/`,
        params,
        config
      );
      return data;
    }  catch (error) {
      if (
        error.response &&
        error.response.data &&
        typeof error.response.data === "object"
      ) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          Error: error.message,
        });
      }
    }
  }
);

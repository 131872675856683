import { createSlice } from "@reduxjs/toolkit";
import {
  getTaxSettings,
  patchTaxSetting,
  postTaxSetting,
  deleteTaxSetting,
} from "./taxSettingsActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  taxes: [],
};

const taxSettingsSlice = createSlice({
  name: "taxSettings",
  initialState,
  reducers: {
    resetTaxesError: (state) => {
      state.error = null;
      state.postError = null

    },
    resetShopSettingError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: {
    [getTaxSettings.pending]: (state) => {
      state.loading = true;
    },
    [getTaxSettings.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.taxes = payload;
    },
    [getTaxSettings.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [postTaxSetting.pending]: (state) => {
      state.loading = true;
    },
    [postTaxSetting.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.taxes = [payload, ...state?.taxes];
    },
    [postTaxSetting.rejected]: (state, { payload }) => {
      state.loading = false;
      state.postError = payload;
    },
    [patchTaxSetting.pending]: (state) => {
      state.loading = true;
    },
    [patchTaxSetting.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.taxes = state?.taxes?.map((tax) =>
        tax?.id?.toString() === payload?.id?.toString() ? payload : tax
      );
    },
    [patchTaxSetting.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [deleteTaxSetting.pending]: (state) => {
      state.loading = true;
    },
    [deleteTaxSetting.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.taxes = state?.taxes?.filter(
        (tax) => tax?.id?.toString() !== payload?.id?.toString()
      );
    },
    [deleteTaxSetting.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
export const { resetShopSettingError, resetTaxesError } = taxSettingsSlice.actions;
export default taxSettingsSlice.reducer;

import { createSlice, current } from "@reduxjs/toolkit";
import {
  getDrivers,
  postDriver,
  updateDriver,
  deleteDriver,
} from "./driverActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  drivers: {
    results: []
  },
};

const driverSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {
    resetError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
    addSocketDriverLocation: (state, action) => {
      const currentDrivers = current(state.drivers.results) || [];
      const found = currentDrivers.find(driver => driver.id == action.payload.driver.id);
      state.drivers.results = found ?
        currentDrivers.map(driver => {
          return driver.id == action.payload.driver.id ? {
            ...driver,
            longitude: action.payload.driver.longitude,
            latitude: action.payload.driver.latitude,
            angle: action.payload.driver.latitude && action.payload.driver.longitude && found.latitude && found.longitude && Math.atan2(-action.payload.driver.latitude + found.latitude, action.payload.driver.longitude - found.longitude) * 180 / Math.PI
          } : driver
        }) : [...currentDrivers, action.payload.driver]
      // state.driverLocation = current(state.driverLocation).filter(driver => driver.id != action.payload.driver.id).push(action.payload.driver);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDrivers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDrivers.fulfilled, (state, action) => {
        state.loading = false;
        state.drivers = action.payload;
      })
      .addCase(getDrivers.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(postDriver.pending, (state) => {
        state.loading = true;
      })
      .addCase(postDriver.fulfilled, (state, action) => {
        const newObj = {
          meta: state.drivers?.meta,
          results: [action.payload, ...state.drivers?.results],
        };
        state.drivers = newObj;
        state.loading = false;
        state.success = true;
      })
      .addCase(postDriver.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(updateDriver.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDriver.fulfilled, (state, action) => {
        // const {
        //   id,
        //   first_name,
        //   last_name,
        //   email,
        //   enrollment_date,
        //   config,
        //   image,
        //   phone_number,
        // } = action.payload;
        const updatedDrivers = state.drivers?.results.map((el) => {
          if (el?.id === action.payload.id) {
            return action.payload;
          } else {
            return el;
          }
        });
        const newObj = {
          meta: state.drivers?.meta,
          results: updatedDrivers,
        };
        state.drivers = newObj;
        state.loading = false;
        state.success = true;
      })
      .addCase(updateDriver.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(deleteDriver.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDriver.fulfilled, (state, action) => {
        const { id } = action.payload;
        const updatedDrivers = state.drivers?.results.filter(
          (el) => el.id !== id
        );
        const newObj = {
          meta: state.drivers?.meta,
          results: updatedDrivers,
        };
        state.drivers = newObj;
        state.loading = false;
      })
      .addCase(deleteDriver.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});
export const {
  resetError,
  addSocketDriverLocation
} = driverSlice.actions;
export default driverSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import {
  getPromoCodes,
  getPromoCode,
  postPromoCode,
  deletePromoCode,
  patchPromoCode,
} from "./promoCodesAction";

const initialState = {
  loading: false,
  error: null,
  success: false,
  codes: {},
  promoCode: null,
};

const promoCodesSlice = createSlice({
  name: "promoCodes",
  initialState,
  reducers: {
    resetPromoCodesError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
    resetPromoCode: (state, action) => {
      state.promoCode = null;
    },
  },
  extraReducers: {
    [getPromoCodes.pending]: (state) => {
      state.loading = true;
    },
    [getPromoCodes.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.codes = payload;
    },
    [getPromoCodes.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getPromoCode.pending]: (state) => {
      state.loading = true;
    },
    [getPromoCode.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.promoCode = payload;
    },
    [getPromoCode.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [postPromoCode.pending]: (state) => {
      state.loading = true;
    },
    [postPromoCode.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.codes = {
        meta: state?.items?.meta,
        results: [payload, ...(state?.codes?.results || [])],
      };
      state.success = true;
    },
    [postPromoCode.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [patchPromoCode.pending]: (state) => {
      state.loading = true;
    },
    [patchPromoCode.fulfilled]: (state, { payload }) => {
      const { id } = payload;
      const updatedPromoCodes = state.codes?.results?.map((el) => {
        if (el?.id === id) {
          return payload;
        } else {
          return el;
        }
      });
      const newObj = {
        meta: state.codes?.meta,
        results: updatedPromoCodes,
      };
      state.codes = newObj;
      state.loading = false;
      state.success = true;
    },
    [patchPromoCode.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [deletePromoCode.pending]: (state) => {
      state.loading = true;
    },
    [deletePromoCode.fulfilled]: (state, { payload }) => {
      const updatedPromoCodes = state.codes?.results?.filter(
        (el) => el?.id !== payload?.id
      );
      const newObj = {
        meta: state.codes?.meta,
        results: updatedPromoCodes,
      };
      state.codes = newObj;
      state.loading = false;
    },
    [deletePromoCode.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
export const { resetPromoCodesError, resetPromoCode } = promoCodesSlice.actions;
export default promoCodesSlice.reducer;

import React from "react";

const TopSoldProducts = React.lazy(() => import("./TopSoldProducts/TopSoldProducts"));
const DynamicReport = React.lazy(() => import("./DynamicReport/DynamicReport"));
const DailyOrders = React.lazy(() => import("./DailyOrders/DailyOrders"));
export {
  TopSoldProducts,
  DynamicReport,
  DailyOrders,
};

import { createSlice } from "@reduxjs/toolkit";
import {
  getMetrcRetailDeliveries,
  getMetrcReatlDeliveriesSync,
} from "./metrcRetailDeliveriesActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  deliveries: {},
};

const metrcRetaildeliveriesSlice = createSlice({
  name: "metrcRetailDeliveries",
  initialState,
  reducers: {
    resetMetrcRetailDeliveryError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: {
    [getMetrcRetailDeliveries.pending]: (state) => {
      state.loading = true;
    },
    [getMetrcRetailDeliveries.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.deliveries = payload;
    },
    [getMetrcRetailDeliveries.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getMetrcReatlDeliveriesSync.pending]: (state) => {
      state.loading = true;
    },
    [getMetrcReatlDeliveriesSync.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [getMetrcReatlDeliveriesSync.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { resetMetrcRetailDeliveryError } =
  metrcRetaildeliveriesSlice.actions;
export default metrcRetaildeliveriesSlice.reducer;

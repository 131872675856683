import { useEffect } from "react";
import "./AddMenu.scss";
import { Typography, Modal, Input, Form, Select } from "antd";
import useAddMenuModal from "../../hooks/useAddMenuModal";
export default function AddMenu({ isOpened, title, subTitle, onCancel }) {
  const {
    modal,
    button,
    formRef,
    dispatch,
    addMenu,
    error,
    loading,
    resetErrors,
  } = useAddMenuModal();

  const add = async (text) => {
    return dispatch(addMenu({ name: text }));
  };

  const onOk = async (name) => {
    const message = await add(name);
    if (message.type === "addmenu/fulfilled") {
      onCancel();
      dispatch(resetErrors());
      formRef.current.setFieldValue("name", "");
    }
  };

  useEffect(() => {
    if (error && !loading) {
      for (let key in error) {
        formRef?.current?.setFields([
          {
            name: key,
            errors: [error[key][0]],
          },
        ]);
      }
    }
  }, [loading, error, isOpened]);

  return (
    <Modal
      open={isOpened}
      title={title}
      onOk={() => {
        formRef.current.submit();
      }}
      okText="Save"
      closeIcon={false}
      centered
      onCancel={() => {
        onCancel();
        dispatch(resetErrors());
        // formRef.current.setFieldValue("name", "");
        formRef?.current?.setFields([{
          name: "name",
          value: '',
          errors: [],
        }]);
      }}
      style={{
        borderTop: `2px solid ${button?.colorPrimary}`,
      }}
      className="addmenu-cont"
      cancelButtonProps={{
        className: "addmenu-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        htmlType: "submit",
        type: "primary",
        className: "addmenu-cont__btn",
      }}
    >
      <Form
        layout="vertical"
        ref={formRef}
        className="addmenu-cont__form"
        onFinish={async (values) => {
          onOk(values["name"]);
        }}
        onFinishFailed={(error) => {
        }}
      >
        <Form.Item
          name="name"
          className="addmenu-cont__form__formItem"
          rules={[
            {
              required: true,
              message: "menu name can't be empty",
            },
          ]}
          colon={false}
          label={
            <Typography.Text
              className="addmenu-cont__form__formItem__text"
              style={{
                color: `${modal.titleColor}`,
              }}
            >
              {subTitle}
            </Typography.Text>
          }
        >
          <Select placeholder="Select"
            options={[
              { label: "WEEDMAPS", value: "WEEDMAPS" },
              { label: "WORDPRESS", value: "WORDPRESS" },
              { label: "LEAFLY", value: "LEAFLY" },
            ]}
            allowClear
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import {
  getReportsList,
  postReport,
  getReportData,
  deleteReport,
  patchReport,
} from "./genericReportsAction";

const initialState = {
  loading: false,
  error: null,
  success: false,
  reportsList: [],
  reportData: [],
};

const genericReports = createSlice({
  name: "genericReports",
  initialState,
  reducers: {
    resetGenericReportError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: {
    [getReportsList.pending]: (state) => {
      state.loading = true;
      state.reportsList = [];
      state.error = null;
      state.success = false;
    },
    [getReportsList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.reportsList = payload;
      state.error = null;
    },
    [getReportsList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.success = false;
    },
    [postReport.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    [postReport.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.reportsList = [...state?.reportsList, payload];
      state.error = null;
      state.success = true;
    },
    [postReport.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.success = false;
    },
    [getReportData.pending]: (state) => {
      state.loading = true;
      state.reportData = [];
      state.error = null;
      state.success = false;
    },
    [getReportData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.reportData = payload;
      state.error = null;
    },
    [getReportData.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.success = false;
    },
    [patchReport.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    [patchReport.fulfilled]: (state, { payload }) => {
      const { id } = payload;
      const updatedReports = state.reportsList?.map((el) => {
        if (el?.id === id) {
          return payload;
        } else {
          return el;
        }
      });

      state.reportsList = updatedReports;
      state.loading = false;
      state.error = false;
      state.success = true;
    },
    [patchReport.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.success = false;
    },
    [deleteReport.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    [deleteReport.fulfilled]: (state, { payload }) => {
      const { id } = payload;
      const updatedReportList = state?.reportsList?.filter(
        (el) => el.id !== id
      );

      state.reportsList = updatedReportList;
      state.loading = false;
      state.error = false;
    },
    [deleteReport.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.success = false;
    },
  },
});
export const { resetGenericReportError } = genericReports.actions;
export default genericReports.reducer;
